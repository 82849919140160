<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">Cupones</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item">
            <a>
              Cupones
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div *ngIf="promocodes.length === 0" class="alert alert-warning">
    <h6>
      No hay cupones registrados
    </h6>
  </div>
  <div *ngFor="let c of promocodes" class="col-sm-4">
    <div class="card">
      <div class="card-body text-center">
        <a (click)="viewDetail(c)" style="cursor: pointer">
          <img src="../../../assets/images/pages/voucher.svg" alt="" class="img-fluid w-50">
          <h5 class="mt-1">{{c.name}}</h5>
          <h5 class="mt-1">{{c.code}}</h5>
          <h5 class="mt-1">
            <ng-container [ngSwitch]="c.type.id">
              <ng-container *ngSwitchCase="1">{{c.discount|number}}%</ng-container>
              <ng-container *ngSwitchCase="2">${{c.discount|number}} </ng-container>
            </ng-container>
            Descuento
          </h5>
        </a>
        <hr>
        <div class="row">
          <div class="col-sm-6 text-left">
            <button *ngIf="canEdit"
                    type="button"
                    (click)="deletePromocode(c)"
                    class="btn btn-icon btn-outline-danger">
              <i class="feather icon-trash-2"></i>
            </button>
          </div>
          <div class="col-sm-6 text-right">
            <div class="custom-control custom-switch">
              <input type="checkbox"
                     [checked]="c.is_active"
                     (change)="switchStatus(c)"
                     [disabled]="!canEdit"
                     class="custom-control-input" id="status{{c.id}}">
              <label class="custom-control-label" for="status{{c.id}}">Activo</label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-sm-12 text-center">
    <button type="button" class="btn btn-primary" (click)="displayNewCodeDialog()">
      <i class="feather icon-plus"></i> GENERAR CUPÓN
    </button>
  </div>

</div>

<swal #deleteSwal></swal>

<ng-template #viewCodeDialog>
  <mat-dialog-content [formGroup]="selectedCodeForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" required placeholder="Ingresa el nombre">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input matInput formControlName="code" required placeholder="Ingresa el código">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="type" required>
              <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
              <mat-option *ngFor="let t of types" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Descuento</mat-label>
            <input matInput formControlName="discount"
                   type="number" required
                   placeholder="Ingresa el descuento">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="custom-control custom-switch">
          <input type="checkbox"
                 formControlName="isActive"
                 class="custom-control-input" id="status_detail">
          <label class="custom-control-label" for="status_detail">Activo</label>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="float-right m-b-50">
      <button class="btn btn-secondary mr-2 float-right" (click)="detailDialogRef.close()">Cerrar</button>
      <button class="btn btn-primary float-right"
              [disabled]="selectedCodeForm.invalid ||selectedCodeForm.pristine"
              (click)="updateCode()">
        Guardar
      </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #newCodeDialog>
  <mat-dialog-content [formGroup]="newCodeForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" required placeholder="Ingresa el nombre">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input matInput formControlName="code" required placeholder="Ingresa el código">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="type" required>
              <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
              <mat-option *ngFor="let t of types" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Descuento</mat-label>
            <input matInput formControlName="discount"
                   type="number" required
                   placeholder="Ingresa el descuento">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="custom-control custom-switch">
          <input type="checkbox"
                 formControlName="isActive"
                 class="custom-control-input" id="status_new" name="status_new">
          <label class="custom-control-label" for="status_new">Activo</label>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="float-right m-b-50">
    <button class="btn btn-secondary mr-2 float-right" (click)="newDialogRef.close()">Cerrar</button>
    <button class="btn btn-primary float-right"
            [disabled]="newCodeForm.invalid ||newCodeForm.pristine"
            (click)="createCode()">
      Guardar
    </button>
  </mat-dialog-actions>
</ng-template>

