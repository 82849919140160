<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">DESCARGAR REPORTE</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <!-- YM - CLIENT INFO -->
  <div class="col-sm-12">
    <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
      <!-- YM - RESERVATION INFO -->
      <div class="card">
        <div class="card-header">
          <h5>Opciones del reporte</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field (click)="fromPicker.open()">
                  <mat-label>Fecha inicial</mat-label>
                  <input matInput [matDatepicker]="fromPicker" formControlName="from">
                  <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                  <mat-datepicker #fromPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field (click)="toPicker.open()">
                  <mat-label>Fecha final</mat-label>
                  <input matInput [matDatepicker]="toPicker" formControlName="to">
                  <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                  <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Yate</mat-label>
                  <mat-select formControlName="yacht">
                    <mat-option [value]="null">Todos</mat-option>
                    <mat-option *ngFor="let y of yachts" [value]="y.id">{{y.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Estatus</mat-label>
                  <mat-select formControlName="status">
                    <mat-option [value]="null">Todos</mat-option>
                    <mat-option *ngFor="let s of status" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - RESERVATION INFO -->

      <div class="col-sm-12">
        <button type="submit"
                class="btn btn-block btn-primary mt-3">
          DESCARGAR REPORTE
        </button>
      </div>
    </form>
  </div>
</div>

<!-- //YM - DASHBOARD -->

