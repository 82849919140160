import {PaymentType, Source} from './reservation';

export default class Membership {
  public id?: number;
  public member_id?: number;
  public plan_id?: number;
  public plan? : MembershipPlan;
  public hours?: number;
  public status_id?: number;
  public status?: MembershipStatus;
  public activation_date?: string;
  public expiration_date?: string;
  public source_id?: number;
  public reference?: string;
  public created_at?: string;
  public updated_at?: string;
  public source?: Source;
  public member?: Member;
  public payments?: MembershipPayment[];
  public comments?: MembershipComment[];
  public usedHours?: number;
  public totalReservations?: number;
}

export class MembershipPlan {
  public id?: number;
  public name?: string;
}

export class MembershipStatus {
  public id?: number;
  public name?: string;
}

export class Member {
  public id?: number;
  public first_name?: string;
  public last_name?: string;
  public email?: string;
  public phone?: string;
}

export class MembershipComment {
  public id?: number;
  public member_id?: number;
  public comment?: string;
  public created_at?: string;
  public updated_at?: string;
}

export class MembershipPayment {
  public id?: number;
  public member_id?: number;
  public amount?: number;
  public method_id?: number;
  public paid_at?: string;
  public created_at?: any;
  public updated_at?: any;
  public method?: PaymentType;
}
