import {Component, OnInit, ViewChild} from '@angular/core';
import Reservation from '../../models/reservation';
import {ReservationsService} from '../../services/reservations.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {YachtService} from '../../services/yacht.service';
import {ReservationStatusService} from '../../services/reservation-status.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styles: [

  ]
})
export class ReservationsComponent implements OnInit {

  displayedColumns = ['id', 'client', 'yacht', 'date', 'status', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filterForm: UntypedFormGroup;
  yachts = [];
  status = [];
  filtered = false;

  constructor(
    private reservationsService: ReservationsService,
    private yachtService: YachtService,
    private statusService: ReservationStatusService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.filterForm = this.formBuilder.group({
      from: [null],
      to: [null],
      yacht: [null],
      status: [null],
    });
  }

  ngOnInit() {
    this.reservationsService.getAllReservations().then(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.yachtService.getAllYachts().then(response => {
      this.yachts = response;
    });

    this.statusService.getAllStatus().then(response => {
      this.status = response['data'];
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSubmitFilterForm() {
    if (this.filterForm.valid) {
      const params = {};

      if (this.filterForm.value.from) {
        params['from'] = this.filterForm.value.from.toISOString().split('T')[0];
      }

      if (this.filterForm.value.to) {
        params['to'] = this.filterForm.value.to.toISOString().split('T')[0];
      }

      if (this.filterForm.value.yacht) {
        params['yacht'] = this.filterForm.value.yacht;
      }

      if (this.filterForm.value.status) {
        params['status'] = this.filterForm.value.status;
      }

      this.reservationsService.getAllReservations(params).then(response => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.filtered = true;
      });
    }
  }

  onClearFilter() {
    if (this.filterForm.dirty && this.filtered) {
      this.filterForm.reset();
      this.filterForm.markAsPristine();
      this.filtered = false;
      this.reservationsService.getAllReservations().then(response => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }

}
