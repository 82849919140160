import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = environment.apiUrl;
  private userToken: string = null;

  constructor(private http: HttpClient) {
    this.getToken();
  }

  signIn(body: any) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.post(this.url + 'auth', body, {headers})
      .toPromise();
      /*.pipe(
        map(resp => {
          this.setLoginInfo(resp);
          return resp;
        })
      )*/
  }

  setLoginInfo(info) {
    this.userToken = info['token'];
    localStorage.setItem('token', this.userToken);

    const now = new Date();
    now.setSeconds(Number(info['expirationTime']));
    localStorage.setItem('expirationTime', now.getTime().toString());

    localStorage.setItem('id', info['id']);
    localStorage.setItem('username', info['username']);
    localStorage.setItem('name', info['name']);
    localStorage.setItem('canEdit', info['canEdit']);
  }

  getToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = null;
    }
    return this.userToken;
  }

  getUserInfo() {
    return {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name')
    };
  }

  getUserId(): number {
    return Number(localStorage.getItem('id'));
  }

  isLoggedIn(): boolean {
    this.getToken();
    if (this.userToken === null) {
      return false;
    }

    const expirationTime = Number(localStorage.getItem('expirationTime'));
    const expirationDate = new Date();
    expirationDate.setTime(expirationTime);

    return expirationDate > new Date();
  }

  canEdit(): boolean {
    const canEdit = Number(localStorage.getItem('canEdit'));
    return canEdit === 1;
  }

  logout() {
    localStorage.clear();
  }
}
