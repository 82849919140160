import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {YachtService} from '../../services/yacht.service';
import {FuelLoadTypesService} from '../../services/fuel-load-types.service';
import {FuelLoadsService} from '../../services/fuel-loads.service';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-fuel-loads',
  templateUrl: './fuel-loads.component.html',
  styles: [
  ]
})
export class FuelLoadsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('editLoadDialog') editLoadDialog: TemplateRef<any>;
  detailDialogRef: MatDialogRef<any> = null;
  dialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
  };

  @ViewChild('successSwal') successSwal: SwalComponent;
  @ViewChild('remarksSwal') remarksSwal: SwalComponent;

  form: UntypedFormGroup;
  file: File;
  filterForm: UntypedFormGroup;

  displayedColumns = [
    'id', 'date', 'yacht', 'type', 'initial_load', 'loaded_quantity', 'final_load', 'price_per_liter', 'total_price', 'invoice', 'actions'
  ];
  loads = [];
  dataSource: MatTableDataSource<any>;
  resultsLength = 0;
  selectedLoad = null;

  types = [];
  yachts = [];
  canEdit: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private typesService: FuelLoadTypesService,
    private yachtService: YachtService,
    private loadsService: FuelLoadsService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this._buildForms();
  }

  ngOnInit() {
    const promises = [
      this.typesService.getAllTypes(),
      this.yachtService.getAllYachts(),
      this.loadsService.getLoads({}),
    ];

    Promise.all(promises).then(responses => {
      this.types = responses[0]['data'];
      this.yachts = responses[1];
      this.dataSource = new MatTableDataSource(responses[2]['data']['loads']);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loads = responses[2]['data']['loads'];
    });
  }

  onChangeAmounts() {
    this.form.get('final_load').setValue(
      this.form.get('initial_load').value + this.form.get('loaded_quantity').value
    );
  }

  onChangePrice() {
    this.form.get('total_price').setValue(
      this.form.get('loaded_quantity').value * this.form.get('price_per_liter').value
    );
  }

  onChangeFile(event) {
    this.file = event.target.files[0];
  }

  async onDownloadLoads() {
    let params = {};
    if (this.filterForm.valid) {
      params = this.filterForm.value;
    }

    await this.loadsService.downloadLoads(params);
  }

  async onDownloadFile(row) {
    await this.loadsService.downloadLoadFile(row.id);
  }

  onSelectLoad(row) {
    this.form.setValue({
      date: row.date,
      type_id: row.type_id,
      yacht_id: row.yacht_id,
      initial_load: row.initial_load,
      final_load: row.final_load,
      loaded_quantity: row.loaded_quantity,
      price_per_liter: row.price_per_liter,
      total_price: row.total_price,
      invoice: row.invoice,
      file: null,
      remarks: row.remarks,
    });
    this.file = null;
    this.selectedLoad = row;

    this.detailDialogRef = this.dialog.open(this.editLoadDialog, this.dialogConfig);

    this.detailDialogRef.beforeClosed().subscribe(() => {
      this.form.reset();
    });
  }

  onViewRemarks(row) {
    this.remarksSwal.swalOptions = {
      text: row.remarks,
      titleText: 'Observaciones',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Cerrar'
    };
    this.remarksSwal.fire();
  }

  onSubmitFilter() {
    if (this.filterForm.valid) {

      this.loadsService.getLoads(this.filterForm.value).then(response => {
        this.loads = response['data']['loads'];
        this.dataSource = new MatTableDataSource(response['data']['loads']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }

  onSubmitForm() {
    if (this.form.valid) {

      const formData = new FormData();
      for (const field in this.form.value) {
        if (this.form.value.hasOwnProperty(field)) {
          if (field === 'file' && this.file) {
            formData.append('file', this.file);
            continue;
          }

          if (this.form.value[field] === null || this.form.value[field] === 'null') { continue; }

          formData.append(field, this.form.value[field]);
        }
      }

      this.loadsService.createLoad(formData).then(() => {
        this.form.reset();
        this.successSwal.fire().then(() => {
          this.loadsService.getLoads({}).then(response => {
            this.loads = response['data']['loads'];
            this.dataSource = new MatTableDataSource(response['data']['loads']);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      }).catch(err => {
        console.log(err);
      });
    }
  }

  onUpdateLoad() {
    if (this.form.valid) {

      const formData = new FormData();
      for (const field in this.form.value) {
        if (this.form.value.hasOwnProperty(field)) {
          if (field === 'file' && this.file) {
            formData.append('file', this.file);
            continue;
          }

          formData.append(field, this.form.value[field]);
        }
      }

      this.loadsService.updateLoad(this.selectedLoad.id, formData).then(() => {
        this.successSwal.fire().then(() => {
          this.detailDialogRef.close();
          this.loadsService.getLoads({}).then(response => {
            this.loads = response['data']['loads'];
            this.dataSource = new MatTableDataSource(response['data']['loads']);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      }).catch(err => {
        console.log(err);
      });
    }
  }

  async onDeleteLoad(loadId: any) {
    Swal.fire({
      titleText: '¿Deseas eliminar este registro?',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: 'red',
      cancelButtonText: 'Cerrar',
      cancelButtonColor: 'gray',
      showCancelButton: true
    }).then(async response => {
      if (response.isConfirmed) {
        await this.loadsService.deleteLoad(loadId);

        this.loads = this.loads.filter((l) => l.id !== loadId);
        this.dataSource = new MatTableDataSource(this.loads);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  private _buildForms() {
    this.form = this.formBuilder.group({
      date: [null, Validators.required],
      type_id: [null, Validators.required],
      yacht_id: [null, Validators.required],
      initial_load: [null, Validators.required],
      final_load: [null, Validators.required],
      loaded_quantity: [null, Validators.required],
      price_per_liter: [null, Validators.required],
      total_price: [null, Validators.required],
      invoice: [null],
      file: [null],
      remarks: [null],
    });

    this.filterForm = this.formBuilder.group({
      from: [null, Validators.required],
      to: [null, Validators.required],
    });

    if (!this.canEdit) {
      this.form.disable();
    }
  }

}
