<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">NUEVA RESERVA</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item">
            <a>
              RESERVA: AÑADIENDO
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <!-- YM - CLIENT INFO -->
  <div class="col-sm-12">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="card">
        <div class="card-header">
          <h5>Datos del Titular</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Nombre</mat-label>
                  <input matInput formControlName="name" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input matInput formControlName="lastName" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>WhatsApp</mat-label>
                  <input matInput formControlName="phone" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Correo</mat-label>
                  <input matInput formControlName="email" required>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - CLIENT INFO -->
      <!-- YM - RESERVATION INFO -->
      <div class="card">
        <div class="card-header">
          <h5>Datos del Itinerario</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Yate</mat-label>
                  <mat-select formControlName="yacht" required (selectionChange)="updatePrices()">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let y of yachts" [value]="y.id" [disabled]="y.is_active_admin === 0">
                      {{y.name}}
                      <span>{{y.active_str}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field (click)="picker.open()">
                  <mat-label>Fecha</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="date">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <!--                  <input matInput formControlName="date" type="date" required>-->
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Horas</mat-label>
                  <mat-select formControlName="hours" required (selectionChange)="updateHoursPrice()">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let p of prices" [value]="p.hours">{{p.hours}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio por hora extra</mat-label>
                  <input matInput formControlName="pricePerExtraHour" type="number" min="0" required
                         (change)="updateExtraHoursPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Horas extra</mat-label>
                  <input matInput formControlName="extraHours" type="number" min="0" required
                         (change)="updateExtraHoursPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Check-in</mat-label>
                  <input matInput formControlName="checkin" type="time" step="60" min="09:00:00" max="21:00:00"
                         placeholder="hh:mm" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Adultos</mat-label>
                  <input matInput formControlName="adults" type="number" min="0" required (change)="updateDockFee()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Niños</mat-label>
                  <input matInput formControlName="children" type="number" min="0" required (change)="updateDockFee()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Estatus</mat-label>
                  <mat-select formControlName="status" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of status" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Estatus de pago</mat-label>
                  <mat-select formControlName="paymentStatus" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of paymentStatus" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - RESERVATION INFO -->
      <!-- YM - PAYMENT EXTRAS -->
      <div class="card">
        <div class="card-header">
          <h5>Extras de Reserva</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <ng-container formArrayName="extras">
              <div *ngFor="let _ of extrasArr.controls; index as i" class="col-sm-3">
                <ng-container [formGroupName]="i.toString()">
                  <label class="floating-label">{{extras[i].name}}</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <mat-checkbox formControlName="selected" (change)="updateExtraPriceField(i)"></mat-checkbox>
                      <mat-form-field class="ml-2">
                        <mat-label>Precio</mat-label>
                        <input matInput formControlName="price" type="number" min="0" (change)="updateExtrasPrice()">
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- //YM - PAYMENT EXTRAS -->
      <!-- YM - PAYMENT INFO -->
      <div class="card">
        <div class="card-header">
          <h5>Datos de Pago</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cuota de Muellaje</mat-label>
                  <input matInput formControlName="dockFee" type="number" min="0"
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cargo por Reserva</mat-label>
                  <input matInput formControlName="reservationFee" type="number" min="0" required
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio de horas seleccionadas</mat-label>
                  <input matInput formControlName="hoursPrice" type="number" min="0" required
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio de horas extra</mat-label>
                  <input matInput formControlName="extraHoursPrice" type="number" min="0" required
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Costo de Reserva</mat-label>
                  <input matInput formControlName="reservationPrice" type="number" min="0" required
                         (change)="updateTotalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cupón de descuento</mat-label>
                  <mat-select formControlName="promocode" (selectionChange)="updatePromocodeDiscount()">
                    <mat-option [value]="null">Sin cupón</mat-option>
                    <mat-option *ngFor="let c of promocodes" [value]="c.id">
                      {{c.code}} (
                      <ng-container *ngIf="c.type.id === 1">
                        -{{c.discount|number}}%
                      </ng-container>
                      <ng-container *ngIf="c.type.id === 2">
                        -${{c.discount|number}}
                      </ng-container>
                      )
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Monto de Descuento</mat-label>
                  <input matInput formControlName="promocodeDiscount" type="number" min="0" required
                         (change)="updateTotalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio total</mat-label>
                  <input matInput formControlName="totalPrice" type="number" min="0" required
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Costo de extras</mat-label>
                  <input matInput formControlName="extrasPrice" type="number" min="0" required
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Comisión Vendedor</mat-label>
                  <input matInput formControlName="salesFee" type="number" min="0" required
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio final</mat-label>
                  <input matInput formControlName="finalPrice" type="number" min="0" required>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - PAYMENT INFO -->

      <div class="card">
        <div class="card-header">
          <h5>Origen de Reservación</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fuente</mat-label>
                  <mat-select formControlName="source" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of sources" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Referencia</mat-label>
                  <input matInput formControlName="reference" required>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- YM - PAYMENT APPLY -->
      <div class="card">
        <div class="card-header">
          <h5>Registro de Pagos</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Monto de Pago</mat-label>
                  <input matInput formControlName="amount" type="number" min="0">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Tipo de Pago</mat-label>
                  <mat-select formControlName="paymentType">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let t of paymentTypes" [value]="t.id">{{t.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fecha de Pago</mat-label>
                  <input matInput formControlName="paymentDate" type="date">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Método de Pago</mat-label>
                  <mat-select formControlName="paymentMethod" (selectionChange)="onSelectMethod()">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let t of paymentMethods" [value]="t.id">{{t.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Tipo de cambio (USD/MXN)</mat-label>
                  <input matInput formControlName="exchange" type="number" min="0">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Referencia</mat-label>
                  <input matInput formControlName="paymentReference">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <button type="submit"
                class="btn btn-block btn-primary mt-3">
          GUARDAR RESERVA
        </button>
      </div>
    </form>
  </div>
</div>

<!-- //YM - DASHBOARD -->

<swal
  #errorSwal
  icon="error"
  titleText="Error"
  text="Ya hay una reservación en la misma hora. Selecciona otro yate o cambia la hora de check-in."
></swal>

<swal
  #exceedsCheckinSwal
  icon="error"
  titleText="Error"
  text="La hora de regreso excede las 10 PM. Cambia la hora de check-in."
></swal>
