import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MembershipPaymentsService {
  private url = environment.apiUrl + 'membership-payments';
  constructor(
    private httpClient: HttpClient
  ) { }

  createPayment(membershipId: string, body: any) {
    return this.httpClient.post(this.url + `/${membershipId}`, body)
      .toPromise();
  }
}
