import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UsersService} from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class RoleResolver implements Resolve<any> {

  constructor(
    private usersService: UsersService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (typeof route.data.moduleId === 'object') {
      return new Promise(async (resolve) => {
        const modules = [];
        for (const module of route.data.moduleId) {
          const response = await this.usersService.checkRoll(module);
          modules.push({moduleId: module, role: response['data']});
        }

        resolve(modules);
      });
    }

    return this.usersService.checkRoll(route.data.moduleId).then(response => {
      return response['data'];
    });
  }
}
