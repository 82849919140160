import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {NewsletterService} from '../../services/newsletter.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['id', 'name', 'email', 'created_at'];
  data = [];
  resultsLength = 0;

  filterForm: UntypedFormGroup;
  canEdit: boolean;

  constructor(
    private newsletterService: NewsletterService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
  ) {
    this.filterForm = formBuilder.group({
      from: [null],
      to: [null],
    });

    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
  }

  ngAfterViewInit() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.newsletterService.getForms(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
          ).pipe(catchError(() => observableOf(null)));
        }),
        map(response => {
          // Flip flag to show that loading has finished.

          if (response['data'].total === 0) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = response['data'].total;
          return response['data'].forms;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  async onDownloadFile() {
    const params = {};

    const from = this.filterForm.value.from;
    if (from) {
      params['from'] = from.toISOString().split('T')[0];
    }

    const to = this.filterForm.value.to;
    if (to) {
      params['to'] = to.toISOString().split('T')[0];
    }

    await this.newsletterService.getFile(params);
  }

  async onDeleteForm(formId: any) {
    Swal.fire({
      titleText: '¿Deseas eliminar este registro?',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: 'red',
      cancelButtonText: 'Cerrar',
      cancelButtonColor: 'gray',
      showCancelButton: true
    }).then(async response => {
      if (response.isConfirmed) {
        await this.newsletterService.deleteFormById(formId);
        this.data = this.data.filter((f) => f.id !== formId);
        this.resultsLength = this.data.length;
      }
    });
  }

}
