<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">REGISTRO DE GASTOS GENERALES</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <!-- YM - CLIENT INFO -->
  <div class="col-sm-12">
    <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
      <div class="card">
        <div class="card-header">
          <h5>Registro de gastos</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input matInput formControlName="date" required type="date">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Categoría</mat-label>
                  <mat-select formControlName="category_id" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let c of categories" [value]="c.id">
                      {{c.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Concepto</mat-label>
                  <input matInput formControlName="description" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cantidad</mat-label>
                  <input matInput formControlName="quantity" type="number" required (input)="onChangeAmounts()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio unitario</mat-label>
                  <input matInput formControlName="unit_price" type="number" required (input)="onChangeAmounts()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio total</mat-label>
                  <input matInput formControlName="total_price" type="number" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Yate</mat-label>
                  <mat-select formControlName="yacht_id" required>
                    <mat-option [value]="-1">N/A</mat-option>
                    <mat-option *ngFor="let y of yachts" [value]="y.id">
                      {{y.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Factura</mat-label>
                  <input matInput formControlName="invoice">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label style="display: block">Archivo</label>
                <input formControlName="file" type="file" (change)="onChangeFile($event)">
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Notas</mat-label>
                  <textarea rows="5" matInput formControlName="remarks"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit" class="btn btn-block btn-primary mt-3">
                GUARDAR MOVIMIENTO
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <form [formGroup]="filterForm" (ngSubmit)="onSubmitFilter()">
      <div class="card">
        <div class="card-header">
          <h5>Filtrar gastos</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fecha inicio</mat-label>
                  <input matInput formControlName="from" required type="date">
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fecha fin</mat-label>
                  <input matInput formControlName="to" required type="date">
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <button type="submit" [disabled]="filterForm.invalid" class="btn btn-block btn-primary mt-3">
                FILTRAR GASTOS
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card table-card latest-activity-card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <button type="button" (click)="onDownloadMovements()" class="btn btn-primary btn-block"><i
              class="feather icon-download"></i> DESCARGAR MOVIMIENTOS
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="example-container">
          <div class="example-table-container">
            <table mat-table [dataSource]="dataSource" class="table table-hover" matSort
                   matSortActive="id" matSortDisableClear matSortDirection="desc">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA</th>
                <td mat-cell *matCellDef="let row">{{row.date | date:'dd/MM/y'}}</td>
              </ng-container>

              <ng-container matColumnDef="category_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CATEGORÍA</th>
                <td mat-cell *matCellDef="let row">{{row.category_id}}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CONCEPTO</th>
                <td mat-cell *matCellDef="let row">{{row.description}}</td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CANTIDAD</th>
                <td mat-cell *matCellDef="let row">{{row.quantity|number:'1.2-2'}}</td>
              </ng-container>

              <ng-container matColumnDef="unitPrice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PRECIO UNITARIO</th>
                <td mat-cell *matCellDef="let row">${{row.unitPrice|number:'1.2-2'}}</td>
              </ng-container>

              <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PRECIO TOTAL</th>
                <td mat-cell *matCellDef="let row">${{row.totalPrice|number:'1.2-2'}}</td>
              </ng-container>

              <ng-container matColumnDef="yacht_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> YATE</th>
                <td mat-cell *matCellDef="let row">{{row.yacht_id}}</td>
              </ng-container>

              <ng-container matColumnDef="invoice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FACTURA</th>
                <td mat-cell *matCellDef="let row">{{row.invoice}}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> ACCIONES</th>
                <td mat-cell *matCellDef="let row">
                  <a *ngIf="row.remarks" (click)="onViewRemarks(row)" style="cursor: pointer;" title="Ver notas">
                    <i class="icon feather icon-book f-w-600 f-16 m-r-15 text-c-blue"></i>
                  </a>
                  <a *ngIf="row.hasFile" (click)="onDownloadFile(row)" style="cursor: pointer;"
                     title="Descargar archivo">
                    <i class="icon feather icon-download f-w-600 f-16 m-r-15 text-c-blue"></i>
                  </a>
                  <a (click)="onSelectMovement(row)" style="cursor: pointer;" title="Editar movimiento">
                    <i class="icon feather icon-edit f-w-600 f-16 m-r-15 text-c-blue"></i>
                  </a>
                  <a *ngIf="canEdit" (click)="onDeleteMovement(row.id)" style="cursor: pointer;" title="Eliminar">
                    <i class="icon feather icon-trash f-w-600 f-16 m-r-15 text-c-red"></i>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <mat-paginator [length]="resultsLength" [pageSize]="10" aria-label=""></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editMovementDialog>
  <mat-dialog-content [formGroup]="form">
    <div class="row">
      <!-- YM - CLIENT INFO -->
      <div class="col-sm-12">
        <form [formGroup]="form" (ngSubmit)="onSubmitForm()">

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input matInput formControlName="date" required type="date">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Categoría</mat-label>
                  <mat-select formControlName="category_id" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let c of categories" [value]="c.id">
                      {{c.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Concepto</mat-label>
                  <input matInput formControlName="description" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cantidad</mat-label>
                  <input matInput formControlName="quantity" type="number" required (input)="onChangeAmounts()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio unitario</mat-label>
                  <input matInput formControlName="unit_price" type="number" required (input)="onChangeAmounts()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio total</mat-label>
                  <input matInput formControlName="total_price" type="number" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Yate</mat-label>
                  <mat-select formControlName="yacht_id" required>
                    <mat-option [value]="-1">N/A</mat-option>
                    <mat-option *ngFor="let y of yachts" [value]="y.id">
                      {{y.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Factura</mat-label>
                  <input matInput formControlName="invoice">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label style="display: block">Archivo</label>
                <input formControlName="file" type="file" (change)="onChangeFile($event)">
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Notas</mat-label>
                  <textarea rows="5" matInput formControlName="remarks"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="float-right m-b-50">
    <button class="btn btn-secondary mr-2 float-right" (click)="detailDialogRef.close()">Cerrar</button>
    <button class="btn btn-primary float-right"
            [disabled]="form.invalid"
            (click)="onUpdateMovement()">
      Actualizar
    </button>
  </mat-dialog-actions>
</ng-template>

<swal #successSwal
      icon="success"
      text="Los cambios se han guardado correctamente"
      [showCancelButton]="false"
      [showConfirmButton]="false"
      timer="2000"
      [timerProgressBar]="true"
></swal>

<swal #remarksSwal></swal>
