import { Component, OnInit } from '@angular/core';
import {ReservationsService} from '../../services/reservations.service';
import {ApexAxisChartSeries, ApexOptions} from 'ng-apexcharts';
import {PaymentsService} from '../../services/payments.service';
import Reservation from '../../models/reservation';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {

  counts = {
    all: 0,
    pending: 0,
    confirmed: 0,
    cancelled: 0
  };
  paymentsKpis = {
    todaySales: 0,
    salesTotal: 0,
    salesAvg: 0,
    salesMin: 0,
    salesMax: 0,
  };
  reservations: Reservation[] = [];

  loading = false;

  constructor(
    private reservationsService: ReservationsService,
    private paymentsService: PaymentsService,
  ) { }

  ngOnInit() {
    /*this.loading = true;

    const getReservCountsProm = this.reservationsService.getAllReservationsCount();
    const getLastReservations = this.reservationsService.getLastReservations();
    const getPaymentsKpis = this.paymentsService.getPaymentsKpis();

    Promise.all([getLastReservations, getReservCountsProm, getPaymentsKpis]).then(resp => {
      this.reservations = resp[0]['data'];
      this.counts = resp[1]['data'];
      this.paymentsKpis = resp[2]['data'];
      this.loading = false;
    });*/
  }

}
