import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MovementCategoriesService} from '../../services/movement-categories.service';
import {YachtService} from '../../services/yacht.service';
import {MovementsService} from '../../services/movements.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styles: ['.cdk-overlay-container { z-index: 9999999;}'],
})
export class MovementsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('editMovementDialog') editMovementDialog: TemplateRef<any>;
  detailDialogRef: MatDialogRef<any> = null;
  dialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
  };

  @ViewChild('successSwal') successSwal: SwalComponent;
  @ViewChild('remarksSwal') remarksSwal: SwalComponent;

  form: UntypedFormGroup;
  file: File;
  filterForm: UntypedFormGroup;

  displayedColumns = ['id', 'date', 'category_id', 'description', 'quantity', 'unitPrice', 'totalPrice', 'yacht_id', 'invoice', 'actions'];
  movements = [];
  dataSource: MatTableDataSource<any>;
  resultsLength = 0;
  selectedMovement = null;

  categories = [];
  yachts = [];
  canEdit: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private categoriesService: MovementCategoriesService,
    private yachtService: YachtService,
    private movementsService: MovementsService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this._buildForms();
  }

  ngOnInit() {
    const promises = [
      this.categoriesService.getAllCategories(),
      this.yachtService.getAllYachts(),
      this.movementsService.getMovements({}),
    ];

    Promise.all(promises).then(responses => {
      this.categories = responses[0]['data'];
      this.yachts = responses[1];
      this.dataSource = new MatTableDataSource(responses[2]['data']['movements']);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.movements = responses[2]['data']['movements'];

    });
  }

  onChangeAmounts() {
    this.form.get('total_price').setValue(
      this.form.get('quantity').value * this.form.get('unit_price').value
    );
  }

  onChangeFile(event) {
    this.file = event.target.files[0];
  }

  async onDownloadMovements() {
    let params = {};
    if (this.filterForm.valid) {
      params = this.filterForm.value;
    }

    await this.movementsService.downloadMovements(params);
  }

  async onDownloadFile(row) {
    await this.movementsService.downloadMovementFile(row.id);
  }

  onSelectMovement(row) {
    this.form.setValue({
      date: row.date,
      category_id: row.real_category_id,
      description: row.description,
      quantity: row.quantity,
      unit_price: row.unitPrice,
      total_price: row.totalPrice,
      yacht_id: row.real_yacht_id,
      invoice: row.invoice,
      file: null,
      remarks: row.remarks,
    });
    this.file = null;
    this.selectedMovement = row;

    this.detailDialogRef = this.dialog.open(this.editMovementDialog, this.dialogConfig);

    this.detailDialogRef.beforeClosed().subscribe(() => {
      this.form.reset();
    });
  }

  onViewRemarks(row) {
    this.remarksSwal.swalOptions = {
      text: row.remarks,
      titleText: 'Observaciones',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Cerrar'
    };
    this.remarksSwal.fire();
  }

  onSubmitFilter() {
    if (this.filterForm.valid) {

      this.movementsService.getMovements(this.filterForm.value).then(response => {
        this.movements = response['data']['movements'];
        this.dataSource = new MatTableDataSource(response['data']['movements']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }

  onSubmitForm() {
    if (this.form.valid) {

      const formData = new FormData();
      for (const field in this.form.value) {
        if (this.form.value.hasOwnProperty(field)) {
          if (field === 'file' && this.file) {
            formData.append('file', this.file);
            continue;
          }

          if (this.form.value[field] === null || this.form.value[field] === 'null') { continue; }

          formData.append(field, this.form.value[field]);
        }
      }

      this.movementsService.createMovement(formData).then(() => {
        this.form.reset();
        this.successSwal.fire().then(() => {
          this.movementsService.getMovements({}).then(response => {
            this.movements = response['data']['movements'];
            this.dataSource = new MatTableDataSource(response['data']['movements']);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      }).catch(err => {
        console.log(err);
      });
    }
  }

  onUpdateMovement() {
    if (this.form.valid) {

      const formData = new FormData();
      for (const field in this.form.value) {
        if (this.form.value.hasOwnProperty(field)) {
          if (field === 'file' && this.file) {
            formData.append('file', this.file);
            continue;
          }

          formData.append(field, this.form.value[field]);
        }
      }

      this.movementsService.updateMovement(this.selectedMovement.id, formData).then(() => {
        this.successSwal.fire().then(() => {
          this.detailDialogRef.close();
          this.movementsService.getMovements({}).then(response => {
            this.movements = response['data']['movements'];
            this.dataSource = new MatTableDataSource(response['data']['movements']);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      }).catch(err => {
        console.log(err);
      });
    }
  }

  async onDeleteMovement(movementId: any) {
    Swal.fire({
      titleText: '¿Deseas eliminar este registro?',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: 'red',
      cancelButtonText: 'Cerrar',
      cancelButtonColor: 'gray',
      showCancelButton: true
    }).then(async response => {
      if (response.isConfirmed) {
        await this.movementsService.deleteMovementById(movementId);

        this.movements = this.movements.filter((m) => m.id !== movementId);
        this.dataSource = new MatTableDataSource(this.movements);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    });
  }

  private _buildForms() {
    this.form = this.formBuilder.group({
      date: [null, Validators.required],
      category_id: [null, Validators.required],
      description: [null, Validators.required],
      quantity: [null, Validators.required],
      unit_price: [null, Validators.required],
      total_price: [null, Validators.required],
      yacht_id: [null, Validators.required],
      invoice: [null],
      file: [null],
      remarks: [null],
    });

    this.filterForm = this.formBuilder.group({
      from: [null, Validators.required],
      to: [null, Validators.required],
    });

    if (!this.canEdit) {
      this.form.disable();
    }
  }

}
