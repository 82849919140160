<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">USUARIOS</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <!-- YM - LAST BOOKINGS -->
  <div class="col-lg-12">
    <div class="card table-card latest-activity-card">
      <div class="card-body p-0">
        <button type="button" class="btn btn-info float-right m-4" (click)="createUser()" *ngIf="canEdit"><i class="feather icon-plus"></i> NUEVO</button>
        <div class="table-responsive">
          <table class="table table-hover" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
              <td mat-cell *matCellDef="let row" class=""> {{row.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE</th>
              <td mat-cell *matCellDef="let row"> {{row.name}}</td>
            </ng-container>

            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="cen"> USUARIO</th>
              <td mat-cell *matCellDef="let row" class=""> {{row.username}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> ACCIONES</th>
              <td mat-cell *matCellDef="let row">
                <a (click)="editPermissions(row)" style="cursor: pointer">
                  <i class="icon feather icon-eye f-w-600 f-16 m-r-15 text-c-blue"></i>
                </a>
                <a (click)="onDeleteUser(row.id)" style="cursor: pointer">
                  <i class="icon feather icon-trash f-w-600 f-16 m-r-15 text-c-red"></i>
                </a>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator pageSize="10" [hidePageSize]="true" showFirstLastButtons></mat-paginator>

        </div>
      </div>
    </div>
  </div>
  <!-- //YM - LAST BOOKINGS -->
</div>
<!-- //YM - DASHBOARD -->

<ng-template #editPermDlg>
  <mat-dialog-content matDialogTitle="Editar rol de usuario" [formGroup]="form">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Rol</mat-label>
            <mat-select formControlName="role" required class="form-control-sm">
              <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
              <mat-option [value]="1">Administrador</mat-option>
              <mat-option [value]="2">Analista de datos</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="float-right m-b-50">
    <button class="btn btn-secondary mr-2 float-right" (click)="detailDialogRef.close()">Cerrar</button>
    <button class="btn btn-primary float-right"
            [disabled]="form.invalid"
            (click)="onUpdatePermissions()">
      Actualizar
    </button>
  </mat-dialog-actions>
</ng-template>
