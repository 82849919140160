<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">Partners</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item">
            <a>
              Partners
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div *ngIf="partners.length === 0" class="alert alert-warning">
    <h6>
      No hay partners registrados
    </h6>
  </div>
  <div *ngFor="let p of partners" class="col-sm-4">
    <div class="card">
      <div class="card-body text-center">
        <a (click)="viewDetail(p)" style="cursor: pointer">
          <img src="https://demo-home.yachtmastersclub.com/_assets/img/logo/yachtmstr_dark.png" alt="" class="img-fluid img-radius w-50 mb-2">
          <h5 class="mt-1">{{p.name}}</h5>
          <h5 class="mt-1">{{p.username}}</h5>
        </a>
      </div>
    </div>
  </div>

</div>

<ng-template #viewCodeDialog >
  <mat-dialog-content [formGroup]="selectedPartnerForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" required placeholder="Ingresa el nombre">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Nombre de usuario</mat-label>
            <input matInput formControlName="username" required placeholder="Ingresa el nombre de usuario">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Precio por hora (USD)</mat-label>
            <input matInput formControlName="pricePerHour" type="number" placeholder="Ingresa el precio por hora (USD)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Muellaje (USD)</mat-label>
            <input matInput formControlName="dockFee" type="number" placeholder="Ingresa el precio de muellaje (USD)">
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="float-right m-b-50">
    <button class="btn btn-secondary mr-2 float-right" (click)="detailDialogRef.close()">Cerrar</button>
    <button class="btn btn-primary float-right"
            [disabled]="selectedPartnerForm.invalid ||selectedPartnerForm.pristine"
            (click)="updatePartner()">
      Guardar
    </button>
  </mat-dialog-actions>
</ng-template>

