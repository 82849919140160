import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;
  serverError = false;
  forbidden = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this._buildForm();
  }

  ngOnInit(): void {}

  submit() {
    this.forbidden = false;
    this.serverError = false;

    if (this.form.valid) {
      this.authService.signIn(this.form.value).then(response => {
        this.authService.setLoginInfo(response['data']);
        this.router.navigate(['/dashboard']);
      }).catch(err => {
        if (err.status >= 400 && err.status < 500) {
          this.forbidden = true;
        } else {
          this.serverError = true;
        }
      });
    }
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
        username: [null, Validators.required],
        password: [null, Validators.required]
      });
  }
}
