<h1 mat-dialog-title>Editando roles de usuario: {{user.name}}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <ng-container formArrayName="modules">
        <ng-container *ngFor="let _ of modules.controls; index as i">
          <ng-container [formGroupName]="i.toString()">
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-12">
                  <h6>{{_.get('module').value}}</h6>
                </div>
                <div class="col-sm-12">
                  <mat-form-field>
                    <mat-select formControlName="role" required class="form-control-sm">
                      <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                      <mat-option [value]="1"><i class="icon feather icon-edit"></i> Admin</mat-option>
                      <mat-option [value]="2"><i class="icon feather icon-eye"></i> Analista</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
  <button class="btn btn-info float-right" mat-button (click)="onSubmit()" [disabled]="form.invalid || !canEdit">
    Guardar
  </button>
</mat-dialog-actions>
