import { Component, OnInit } from '@angular/core';
import {NoLimitsMembershipsService} from '../../services/no-limits-memberships.service';
import {NoLimitsMembership} from '../../models/no-limits-membership';

@Component({
  selector: 'app-no-limits-memberships',
  templateUrl: './no-limits-memberships.component.html',
  styles: [
  ]
})
export class NoLimitsMembershipsComponent implements OnInit {

  memberships: NoLimitsMembership[] = [];

  constructor(
    private nlMembershipService: NoLimitsMembershipsService
  ) { }

  ngOnInit(): void {
    this.nlMembershipService.getAllMembreships().then(response => {
      this.memberships = response['data'];
    });
  }

}
