import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Promocode, PromocodeType} from '../../models/reservation';
import {PromocodesService} from '../../services/promocodes.service';
import {PromocodeTypesService} from '../../services/promocode-types.service';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styles: []
})
export class CouponsComponent implements OnInit {
  @ViewChild('deleteSwal') deleteSwal: SwalComponent;
  @ViewChild('viewCodeDialog') viewCodeDialog: TemplateRef<any>;
  @ViewChild('newCodeDialog') newCodeDialog: TemplateRef<any>;

  dialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
  };
  detailDialogRef: MatDialogRef<any> = null;
  newDialogRef: MatDialogRef<any> = null;

  promocodes: Promocode[] = [];
  types: PromocodeType[] = [];

  selectedCode: Promocode = null;
  selectedCodeForm: UntypedFormGroup;
  newCodeForm: UntypedFormGroup;
  canEdit: boolean;

  constructor(
    private promocodesService: PromocodesService,
    private promocodeTypesService: PromocodeTypesService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this._buildForms();
  }

  ngOnInit(): void {
    const promises = [
      this.promocodesService.getAllPromocodes(),
      this.promocodeTypesService.getAllTypes()
    ];

    Promise.all(promises).then(resp => {
      this.promocodes = resp[0]['data'];
      this.types = resp[1]['data'];

    });
  }

  createCode() {
    if (this.newCodeForm.valid) {
      this.promocodesService.createPromocode(this.newCodeForm.value).then(
        async () => {
          await this._refreshPromocodes();
          this.newDialogRef.close();
          this.newCodeForm.reset();
        }
      );
    }
  }

  deletePromocode(code: Promocode) {
    this.deleteSwal.swalOptions = {
      title: `¿Deseas eliminar el cupón ${code.code}?`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusCancel: true,
      confirmButtonText: 'Eliminar',
    };
    this.deleteSwal.fire().then(result => {
      if (result.isConfirmed) {
        this.promocodesService.deletePromocode(code.id).then(
          async () => {
            await this._refreshPromocodes();
          }
        );
      }
    });
  }

  displayNewCodeDialog() {
    this._openNewCodeDialog();
  }

  async switchStatus(code: Promocode) {
    await this.promocodesService.switchPromocodeStatus(code.id);
    await this._refreshPromocodes();
  }

  async updateCode() {
    if (this.selectedCodeForm.valid) {

      await this.promocodesService.updatePromocode(this.selectedCode.id, this.selectedCodeForm.value);
      await this._refreshPromocodes();
      this.detailDialogRef.close();
      this.selectedCodeForm.markAsPristine();
    }
  }

  viewDetail(code: Promocode) {
    this._setFormData(code);
    this._openViewDetailDialog();
  }

  private _buildForms() {
    this.selectedCodeForm = this.formBuilder.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      type: [null, Validators.required],
      discount: [null, Validators.required],
      isActive: [false]
    });

    this.newCodeForm = this.formBuilder.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      type: [null, Validators.required],
      discount: [null, Validators.required],
      isActive: [false]
    });

    if (!this.canEdit) {
      this.selectedCodeForm.disable();
      this.newCodeForm.disable();
    }
  }

  private _refreshPromocodes() {
    return this.promocodesService.getAllPromocodes().then(resp => {
      this.promocodes = resp['data'];
    });
  }

  private _openViewDetailDialog() {
    this.detailDialogRef = this.dialog.open(this.viewCodeDialog, this.dialogConfig);
  }

  private _openNewCodeDialog() {
    this.newDialogRef = this.dialog.open(this.newCodeDialog, this.dialogConfig);
  }

  private _setFormData(code: Promocode) {
    this.selectedCodeForm.setValue({
      name: code.name,
      code: code.code,
      type: code.type.id,
      discount: code.discount,
      isActive: code.is_active
    });

    this.selectedCode = code;
  }
}
