<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">DETALLES DE RESERVA</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item">
            <a>
              RESERVA: {{reservationId}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <div class="col-md-8">
    <form [formGroup]="reservationInfoForm" (ngSubmit)="submitReservationInfo()">

      <div class="card">
        <div class="card-header">
          <h5>Datos del Titular</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Nombre</mat-label>
                  <input matInput formControlName="name" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input matInput formControlName="lastName" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>WhatsApp</mat-label>
                  <input matInput formControlName="phone" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Correo</mat-label>
                  <input matInput formControlName="email" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cupón / Folio</mat-label>
                  <input matInput formControlName="folio">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - CLIENT INFO -->
      <!-- YM - RESERVATION INFO -->
      <div class="card">
        <div class="card-header">
          <h5>Datos del Itinerario</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Yate</mat-label>
                  <mat-select formControlName="yacht" required (selectionChange)="updatePrices()">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let y of yachts" [value]="y.id" [disabled]="y.is_active_admin === 0">
                      {{y.name}}
                      <span>{{y.active_str}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field (click)="picker.open()">
                  <mat-label>Fecha</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="date">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <!--                  <input matInput formControlName="date" type="date" required>-->
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Horas</mat-label>
                  <mat-select formControlName="hours" required (selectionChange)="updateHoursPrice()">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let p of prices" [value]="p.hours">{{p.hours}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio por hora extra</mat-label>
                  <input matInput formControlName="pricePerExtraHour" type="number" min="0" required
                         (change)="updateExtraHoursPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Horas extra</mat-label>
                  <input matInput formControlName="extraHours" type="number" min="0" required
                         (change)="updateExtraHoursPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Check-in</mat-label>
                  <input matInput formControlName="checkin" type="time" step="60" min="09:00:00" max="21:00:00"
                         required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Adultos</mat-label>
                  <input matInput formControlName="adults" type="number" min="0" required (change)="updateDockFee()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Niños</mat-label>
                  <input matInput formControlName="children" type="number" min="0" required (change)="updateDockFee()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Estatus</mat-label>
                  <mat-select formControlName="status" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of status" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Estatus de pago</mat-label>
                  <mat-select formControlName="paymentStatus" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of paymentStatus" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - RESERVATION INFO -->
      <!-- YM - PAYMENT EXTRAS -->
      <div class="card">
        <div class="card-header">
          <h5>Extras de Reserva</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <ng-container formArrayName="extras">
              <div *ngFor="let _ of extrasArr.controls; index as i" class="col-md-6 col-sm-1">
                <ng-container [formGroupName]="i.toString()">
                  <label class="floating-label">{{extras[i].name}}</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <mat-checkbox formControlName="selected" (change)="updateExtraPriceField(i)"></mat-checkbox>
                      <mat-form-field class="ml-2">
                        <mat-label>Precio</mat-label>
                        <input matInput formControlName="price" type="number" min="0" (change)="updateExtrasPrice()">
                      </mat-form-field>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- //YM - PAYMENT EXTRAS -->

      <div class="card">
        <div class="card-header">
          <h5>Datos de Facturación</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-radio-group formControlName="withInvoice" (change)="onChangeInvoice($event)">
                  <mat-label style="margin-right: 10px;">Con Factura: </mat-label>
                  <mat-radio-button [value]="1" style="margin: 5px 5px 5px 10px;">Sí</mat-radio-button>
                  <mat-radio-button [value]="0" style="margin: 5px">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>% de Impuestos</mat-label>
                  <input matInput formControlName="invoiceFees" type="number" min="0"
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>No. de Factura</mat-label>
                  <input matInput formControlName="invoiceNo">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>RFC Cliente</mat-label>
                  <input matInput formControlName="clientRfc">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- YM - PAYMENT INFO -->
      <div class="card">
        <div class="card-header">
          <h5>Datos de Pago</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cuota de Muellaje</mat-label>
                  <input matInput formControlName="dockFee" type="number" min="0"
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cargo por Reserva</mat-label>
                  <input matInput formControlName="reservationFee" type="number" min="0" required
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio de horas seleccionadas</mat-label>
                  <input matInput formControlName="hoursPrice" type="number" min="0" required
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio de horas extra</mat-label>
                  <input matInput formControlName="extraHoursPrice" type="number" min="0" required
                         (change)="updateReservationPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Costo de Reserva</mat-label>
                  <input matInput formControlName="reservationPrice" type="number" min="0" required
                         (change)="updateTotalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Cupón de descuento</mat-label>
                  <mat-select formControlName="promocode" (selectionChange)="updatePromocodeDiscount()">
                    <mat-option [value]="null">Sin cupón</mat-option>
                    <mat-option *ngFor="let c of promocodes" [value]="c.id">
                      {{c.code}} (
                      <ng-container *ngIf="c.type.id === 1">
                        -{{c.discount|number}}%
                      </ng-container>
                      <ng-container *ngIf="c.type.id === 2">
                        -${{c.discount|number}}
                      </ng-container>
                      )
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Monto de Descuento</mat-label>
                  <input matInput formControlName="promocodeDiscount" type="number" min="0" required
                         (change)="updateTotalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio total</mat-label>
                  <input matInput formControlName="totalPrice" type="number" min="0" required
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Costo de extras</mat-label>
                  <input matInput formControlName="extrasPrice" type="number" min="0" required
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Comisión Vendedor</mat-label>
                  <input matInput formControlName="salesFee" type="number" min="0" required
                         (change)="updateFinalPrice()">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Precio final</mat-label>
                  <input matInput formControlName="finalPrice" type="number" min="0" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit"
                      [disabled]="reservationInfoForm.invalid || reservationInfoForm.pristine"
                      class="btn btn-block btn-primary mt-3">
                ACTUALIZAR RESERVACIÓN
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- YM - PAYMENT APPLY -->
    <div class="card">
      <div class="card-header">
        <h5>Registro de Pagos</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="paymentForm" (ngSubmit)="submitPaymentForm()">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Monto de Pago</mat-label>
                  <input matInput formControlName="amount" type="number" (input)="onChangePercentage(paymentForm)">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Tipo de Pago</mat-label>
                  <mat-select formControlName="type">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let t of paymentTypes" [value]="t.id">{{t.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fecha de Pago</mat-label>
                  <input matInput formControlName="date" type="date">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Método de Pago</mat-label>
                  <mat-select formControlName="method">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let t of paymentMethods" [value]="t.id">{{t.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Moneda</mat-label>
                  <mat-select formControlName="currency" (selectionChange)="onSelectCurrency(paymentForm)">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let c of currencies" [value]="c.id">{{c.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Tipo de cambio (MXN)</mat-label>
                  <input matInput formControlName="exchange" type="number" min="0">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Referencia</mat-label>
                  <input matInput formControlName="reference">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Comisión Tarjeta (%)</mat-label>
                  <input matInput formControlName="fees" type="number" min="0" (input)="onChangePercentage(paymentForm)">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Comisión Tarjeta (Neto)</mat-label>
                  <input matInput formControlName="net_fees" type="number" min="0" (input)="onChangeNet(paymentForm)">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit"
                      [disabled]="paymentForm.invalid || paymentForm.pristine"
                      class="btn btn-block btn-primary mt-3">
                APLICAR PAGO
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- //YM - PAYMENT APPLY -->
    <!-- YM - PAYMENT LOGS -->
    <div class="card">
      <div class="card-header">
        <h5>Historial de Pagos</h5>
      </div>
      <div class="card-body table-border-style">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>NO.</th>
              <th>MONTO</th>
              <th>TIPO</th>
              <th>MÉTODO</th>
              <th>MONEDA</th>
              <th>FECHA</th>
              <th>REFERENCIA</th>
              <th>ACCIONES</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of payments">
              <ng-container *ngIf="p.isFee === 0">
                <td>{{p.count}}</td>
                <td>${{(p.mxn_amount ? p.mxn_amount : p.amount)|number:'1.2-2'}} MXN</td>
                <td>{{p.type.name}}</td>
                <td>{{p.method?.name}}</td>
                <td>{{p.currency}}</td>
                <td>{{p.paid_at|date:'dd/MM/y'}}</td>
                <td>{{p.reference}}</td>
                <td>
                  <a (click)="onSelectPayment(p)" style="cursor:pointer;" title="Editar">
                    <i class="icon feather icon-edit f-w-600 f-16 m-r-15 text-c-green"></i>
                  </a>
                  <a *ngIf="canEditPayments" (click)="selectedPayment = p; deleteSwal.fire()" style="cursor:pointer;" title="Eliminar">
                    <i class="icon feather icon-trash f-w-600 f-16 m-r-15 text-c-red"></i>
                  </a>
                </td>
              </ng-container>
              <ng-container *ngIf="p.isFee === 1">
                <td>{{p.count}}</td>
                <td>${{p.mxn_amount|number:'1.2-2'}} MXN</td>
                <td>{{p.type.name}}</td>
                <td>{{p.method?.name}}</td>
                <td>{{p.currency}}</td>
                <td>{{p.paid_at|date:'dd/MM/y'}}</td>
                <td>{{p.reference}}</td>
                <td></td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- //YM - PAYMENT LOGS -->
    <!-- YM - RESERVATION SOURCE -->
    <div class="card">
      <div class="card-header">
        <h5>Origen de Reservación</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="sourceForm" (ngSubmit)="submitSourceForm()">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fuente</mat-label>
                  <mat-select formControlName="source">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of sources" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Referencia</mat-label>
                  <input matInput formControlName="reference">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit"
                      [disabled]="!canUpdateSource || sourceForm.invalid || sourceForm.pristine"
                      class="btn btn-block btn-primary mt-3">
                ACTUALIZAR ORIGEN
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- //YM - RESERVATION SOURCE -->
  <div class="col-md-4">
    <div class="card hdd-right-inner">
      <div class="card-header">
        <h5>Resumen de Reserva</h5>
      </div>
      <div class="card-body">
        <a
          href="https://api.whatsapp.com/send/?phone={{reservation.client?.phone}}&text=Hola {{reservation.client?.name}}, tu reserva en Yacht Masters se ha actualizado. Puedes consultar todos los cambios en este link: https://reservaciones.yachtmastersclub.com/?rid={{reservation.hash}}"
          class="alert alert-success d-block text-center text-uppercase" target="_blank"><i
          class="feather icon-check-circle mr-2"></i>ENVIAR TICKET</a>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Yate</label>
            <div class="media-body">
              <p class="mb-0">{{reservation.yacht?.name}}</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Fecha</label>
            <div class="media-body">
              <p class="mb-0"><i class="feather icon-calendar mr-1"></i>{{reservation.reservation_date|date:'dd/MM/y'}}
              </p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Check In</label>
            <div class="media-body">
              <p class="mb-0">
                <i class="feather icon-clock mr-1"></i>
                {{reservationTime|date:'hh:mm a'}}
              </p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Horas</label>
            <div class="media-body">
              <p class="mb-0">{{reservation.hours|number}} Horas</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Total</label>
            <div class="media-body">
              <p class="mb-0">${{reservation.final|number:'1.2-2'}}</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Pagado</label>
            <div class="media-body">
              <p class="mb-0">${{reservation.paid|number:'1.2-2'}}</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Pendiente</label>
            <div class="media-body">
              <p class="mb-0">${{reservation.pending|number:'1.2-2'}}</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Origen</label>
            <div class="media-body">
              <p class="mb-0">{{reservation.source?.name}}</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Referencia</label>
            <div class="media-body">
              <p class="mb-0">{{reservation.reference}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>


    <div class="card hdd-right-inner">
      <div class="card-header">
        <h5>Control Interno</h5>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Creada por:</label>
            <div class="media-body">
              <p class="mb-0">{{reservation.created_by ? reservation.created_by.name : 'N/A'}}</p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Creada el:</label>
            <div class="media-body">
              <p class="mb-0">
                <i class="feather icon-calendar mr-1"></i>{{reservation.created_at | date:'dd/MM/y h:mm:ss a'}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>


    <div class="card hdd-right-inner">
      <div class="card-header">
        <h5>Seguimiento de Reserva</h5>
      </div>
      <div class="card-body">
        <div class="col-sm-12">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <mat-label>Agregar comentario</mat-label>
              <textarea matInput [formControl]="commentCtrl" rows="5" style="resize: none"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-12">
          <button type="button"
                  [disabled]="!canEdit || commentCtrl.invalid"
                  (click)="submitComment()"
                  class="btn btn-block btn-primary mt-3">
            AGREGAR COMENTARIO
          </button>
        </div>
      </div>
    </div>

    <div class="card task-card">
      <div class="card-header">
        <h5>Comentarios de Reserva</h5>
      </div>
      <div class="card-body">
        <div *ngIf="comments.length === 0" class="alert alert-secondary text-center">
          No hay comentarios
        </div>
        <ul *ngIf="comments.length > 0" class="list-unstyled task-list">
          <li *ngFor="let c of comments">
            <i class="feather icon-check f-w-600 task-icon bg-c-green"></i>
            <p class="m-b-5"><span class="text-c-blue">{{c.created_at|date:'dd/MM/y hh:mm a'}}</span></p>
            <h6 class="text-muted">{{c.comment}}</h6>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - DASHBOARD -->

<swal
  #errorSwal
  icon="error"
  titleText="Error"
  text="Ya hay una reservación en la misma hora. Selecciona otro yate o cambia la hora de check-in."
></swal>

<swal
  #exceedsCheckinSwal
  icon="error"
  titleText="Error"
  text="La hora de regreso excede las 10 PM. Cambia la hora de check-in."
></swal>

<swal
  #pendingSwal
  icon="warning"
  titleText="Saldo pendiente"
  text="Hay un saldo pendiente de ${{reservation.pending|number:'1.2-2'}}. ¿Deseas finalizar la reservación?"
  [showCancelButton]="true"
  [focusCancel]="true"
  [allowOutsideClick]="true"
></swal>

<swal
  #deleteSwal
  icon="warning"
  titleText="Eliminar pago"
  text="¿Desea eliminar el pago?"
  [showCancelButton]="true"
  [focusCancel]="true"
  [allowOutsideClick]="true"
  (confirm)="deletePayment()"
></swal>

<swal
  #yachtSwal
  icon="question"
  titleText="Actualizar precios"
  text="Seleccionaste un yate diferente. ¿Deseas que se conserven los montos actuales o que se calculen con el precio del yate seleccionado?"
  [showCancelButton]="true"
  [focusCancel]="true"
  cancelButtonText="Conservar"
  confirmButtonText="Recalcular"
  [allowOutsideClick]="false"
></swal>

<ng-template #editPaymentDialog>
  <mat-dialog-content [formGroup]="selectedPaymentForm">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Monto de Pago</mat-label>
            <input matInput formControlName="amount" type="number">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Tipo de Pago</mat-label>
            <mat-select formControlName="type">
              <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
              <mat-option *ngFor="let t of paymentTypes" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Fecha de Pago</mat-label>
            <input matInput formControlName="date" type="date">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Método de Pago</mat-label>
            <mat-select formControlName="method">
              <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
              <mat-option *ngFor="let t of paymentMethods" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select formControlName="currency" (selectionChange)="onSelectCurrency(selectedPaymentForm)">
              <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
              <mat-option *ngFor="let c of currencies" [value]="c.id">{{c.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Tipo de cambio (MXN)</mat-label>
            <input matInput formControlName="exchange" type="number" min="0">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Referencia</mat-label>
            <input matInput formControlName="reference">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Comisión Tarjeta (%)</mat-label>
            <input matInput formControlName="fees" type="number" min="0" (input)="onChangePercentage(selectedPaymentForm)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Comisión Tarjeta (Neto)</mat-label>
            <input matInput formControlName="net_fees" type="number" min="0" (input)="onChangeNet(selectedPaymentForm)">
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="float-right m-b-50">
    <button class="btn btn-secondary mr-2 float-right" (click)="detailDialogRef.close()">Cerrar</button>
    <button class="btn btn-primary float-right"
            [disabled]="!canEditPayments || selectedPaymentForm.invalid"
            (click)="updatePayment()">
      Actualizar
    </button>
  </mat-dialog-actions>
</ng-template>
