import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MembershipStatusService {
  private url = environment.apiUrl + 'membership-status';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllMembreshipStatus() {
    return this.httpClient.get(this.url)
      .toPromise();
  }
}
