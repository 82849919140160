import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ReservationDetailComponent} from './components/reservation-detail/reservation-detail.component';
import {NewReservationComponent} from './components/new-reservation/new-reservation.component';
import {ReservationsComponent} from './components/reservations/reservations.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {MainComponent} from './layout/main/main.component';
import {CouponsComponent} from './components/coupons/coupons.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {AnnualMembershipsComponent} from './components/annual-memberships/annual-memberships.component';
import {AnnualMembershipDetailComponent} from './components/annual-membership-detail/annual-membership-detail.component';
import {NoLimitsMembershipsComponent} from './components/no-limits-memberships/no-limits-memberships.component';
import {NoLimitsMembershipDetailComponent} from './components/no-limits-membership-detail/no-limits-membership-detail.component';
import {YachtsComponent} from './components/yachts/yachts.component';
import {DownloadReportComponent} from './components/download-report/download-report.component';
import {PartnersComponent} from './components/partners/partners.component';
import {InsuranceFormsComponent} from './components/insurance-forms/insurance-forms.component';
import {MovementsComponent} from './components/movements/movements.component';
import {FuelLoadsComponent} from './components/fuel-loads/fuel-loads.component';
import {UsersComponent} from './components/users/users.component';
import {RoleResolver} from './resolvers/role.resolver';
import {NewsletterComponent} from './components/newsletter/newsletter.component';

const resolve = {
  role: RoleResolver
};

const routes: Routes = [
  // {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '', component: LoginComponent},
  {
    path: '', component: MainComponent, canActivate: [AuthGuard], children:
      [
        {path: 'dashboard', component: DashboardComponent},
        {path: 'usuarios', component: UsersComponent, resolve, data: {moduleId: 6}},
        {path: 'yates', component: YachtsComponent, resolve, data: {moduleId: 7}},
        // {path: 'editar', component: EditYachtPricesComponent, resolve},
        {path: 'partners', component: PartnersComponent, resolve, data: {moduleId: 9}},
        {path: 'seguros', component: InsuranceFormsComponent, resolve, data: {moduleId: 8}},
        {path: 'newsletter', component: NewsletterComponent, resolve, data: {moduleId: 11}},
        {
          path: 'reservaciones', children:
            [
              {path: '', component: ReservationsComponent},
              {path: 'nueva', component: NewReservationComponent, resolve, data: {moduleId: 1}},
              {path: 'reporte', component: DownloadReportComponent},
              {path: ':id', component: ReservationDetailComponent, resolve, data: {moduleId: [1, 2]}},
            ]
        },
        {
          path: 'membresias', children:
            [
              {
                path: 'anuales', children:
                  [
                    {path: '', component: AnnualMembershipsComponent},
                    {path: ':id', component: AnnualMembershipDetailComponent, resolve, data: {moduleId: 10}}
                  ]
              },
              {
                path: 'sin-fronteras', children:
                [
                  {path: '', component: NoLimitsMembershipsComponent},
                  {path: ':id', component: NoLimitsMembershipDetailComponent, resolve, data: {moduleId: 10}},
                ]
              }
            ]
        },
        {path: 'calendario', component: CalendarComponent},
        {path: 'cupones', component: CouponsComponent, resolve, data: {moduleId: 5}},
        {path: 'gastos-generales', component: MovementsComponent, resolve, data: {moduleId: 4}},
        {path: 'combustibles', component: FuelLoadsComponent, resolve, data: {moduleId: 3}},
      ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
