import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgxLoadingModule} from 'ngx-loading';
import { ReservationDetailComponent } from './components/reservation-detail/reservation-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NewReservationComponent } from './components/new-reservation/new-reservation.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { MainComponent } from './layout/main/main.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { CouponsComponent } from './components/coupons/coupons.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import { LoginComponent } from './components/login/login.component';
import {AuthInterceptorService} from './services/auth-interceptor.service';
import { AnnualMembershipsComponent } from './components/annual-memberships/annual-memberships.component';
import { AnnualMembershipDetailComponent } from './components/annual-membership-detail/annual-membership-detail.component';
import { NoLimitsMembershipsComponent } from './components/no-limits-memberships/no-limits-memberships.component';
import { NoLimitsMembershipDetailComponent } from './components/no-limits-membership-detail/no-limits-membership-detail.component';
import { YachtsComponent } from './components/yachts/yachts.component';
import { DownloadReportComponent } from './components/download-report/download-report.component';
import { PartnersComponent } from './components/partners/partners.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { InsuranceFormsComponent } from './components/insurance-forms/insurance-forms.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { EditYachtPricesComponent } from './components/edit-yacht-prices/edit-yacht-prices.component';
import {MatButtonModule} from '@angular/material/button';
import { MovementsComponent } from './components/movements/movements.component';
import { FuelLoadsComponent } from './components/fuel-loads/fuel-loads.component';
import { UsersComponent } from './components/users/users.component';
import {LoaderInterceptor} from './interceptors/loader.interceptor';
import { EditUserRolesComponent } from './components/edit-user-roles/edit-user-roles.component';
import { UserComponent } from './components/user/user.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';

registerLocaleData(localeMx);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ReservationDetailComponent,
    NewReservationComponent,
    ReservationsComponent,
    CalendarComponent,
    MainComponent,
    CouponsComponent,
    LoginComponent,
    AnnualMembershipsComponent,
    AnnualMembershipDetailComponent,
    NoLimitsMembershipsComponent,
    NoLimitsMembershipDetailComponent,
    YachtsComponent,
    DownloadReportComponent,
    PartnersComponent,
    InsuranceFormsComponent,
    EditYachtPricesComponent,
    MovementsComponent,
    FuelLoadsComponent,
    UsersComponent,
    EditUserRolesComponent,
    UserComponent,
    NewsletterComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        NgxLoadingModule.forRoot({fullScreenBackdrop: true}),
        BrowserAnimationsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatNativeDateModule,
        CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
        SweetAlert2Module.forRoot(),
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
