<h1 mat-dialog-title>Crear usuario</h1>
<div mat-dialog-content>
  <form [formGroup]="form" autocomplete="off">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" required autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Correo electrónico</mat-label>
            <input matInput formControlName="email" required type="email" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Usuario</mat-label>
            <input matInput formControlName="username" required autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="password" required type="password" autocomplete="new-password">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
  <button class="btn btn-info float-right" mat-button (click)="onSubmit()" [disabled]="form.invalid">
    Guardar
  </button>
</mat-dialog-actions>
