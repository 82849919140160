import { Component, OnInit } from '@angular/core';
import {Yacht} from '../../models/reservation';
import {YachtService} from '../../services/yacht.service';
import {MatDialog} from '@angular/material/dialog';
import {EditYachtPricesComponent} from '../edit-yacht-prices/edit-yacht-prices.component';
import {ActivatedRoute} from '@angular/router';
import {RoledComponent} from '../../classes/extends/roled-component';

@Component({
  selector: 'app-yachts',
  templateUrl: './yachts.component.html',
  styles: [
  ]
})
export class YachtsComponent implements OnInit {

  // loading = false;
  yachts: Yacht[] = [];
  canEdit: any;

  constructor(
    private yachtsService: YachtService,
    public dialog: MatDialog,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this.yachtsService.getAllYachts().then(response => {
      this.yachts = response;
    });
  }

  async switchStatus(yachtId: number, type: string) {
    await this.yachtsService.switchYachtStatusById(yachtId, type);
  }

  onSelectYacht(yachtId: any) {
    const canEdit = this.canEdit;
    this.yachtsService.getYachtById(yachtId).then(response => {
      const dialogRef = this.dialog.open(EditYachtPricesComponent, {
        data: {yacht: response['data'], canEdit},
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== false) {
          this.yachtsService.getAllYachts().then(r => {
            this.yachts = r;
          });
        }
      }).unsubscribe();
    });
  }

}
