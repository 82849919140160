import {Component, Inject, OnInit} from '@angular/core';
import {Yacht} from '../../models/reservation';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {YachtService} from '../../services/yacht.service';

@Component({
  selector: 'app-edit-yacht-prices',
  templateUrl: './edit-yacht-prices.component.html',
  styles: []
})
export class EditYachtPricesComponent implements OnInit {

  yacht: Yacht;
  form: UntypedFormGroup;
  canEdit: boolean;
  pricesArr = new UntypedFormArray([]);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditYachtPricesComponent>,
    public yachtService: YachtService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.yacht = this.data.yacht;
    this.canEdit = this.data.canEdit;
    this._buildForm();
  }

  ngOnInit(): void {
    this.form.patchValue({name: this.yacht.name});
    for (const price of this.yacht.prices) {
      this.pricesArr.push(this.formBuilder.group({
        id: [{value: price.id, disabled: !this.canEdit}],
        price: [{value: price.price, disabled: !this.canEdit}, Validators.required],
        online_price: [{value: price.online_price, disabled: !this.canEdit}, Validators.required],
        usd_online_price: [{value: price.usd_online_price, disabled: !this.canEdit}],
      }));
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.yachtService.updateYachtById(this.yacht.id, this.form.value).then(response => {
        this.dialogRef.close();
      }).catch(err => {
        this.dialogRef.close();
      });
    }
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      prices: this.formBuilder.array([])
    });

    this.pricesArr = this.form.get('prices') as UntypedFormArray;

    if (!this.canEdit) {
      this.form.disable();
    }
  }

}
