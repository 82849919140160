import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SortDirection} from '@angular/material/sort';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class InsuranceFormsService {
  private url = environment.apiUrl + 'insurance-forms';
  constructor(
    private httpClient: HttpClient
  ) { }

  getInsuranceForms(sort: string, order: SortDirection, page) {
    return this.httpClient.get(this.url, {params: {sort, order, page}});
  }

  getFile(params: any) {
    return this.httpClient.get(this.url + `/report`,
      { observe: 'response', responseType: 'arraybuffer', params})
      .toPromise()
      .then(response => {
        const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
        const blob = new Blob([response.body], {type: response.type.toString()});
        saveAs(blob, filename);
      });
  }

  deleteFormById(formId: any) {
    return this.httpClient.delete(this.url + `/${formId}`)
      .toPromise();
  }
}
