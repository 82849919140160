<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">LISTA DE RESERVACIONES</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <!-- YM - LAST BOOKINGS -->
  <div class="col-lg-12">
    <div class="card table-card latest-activity-card">
      <div class="card-header">
        <h5>Últimas Reservas</h5>
      </div>
      <div class="card-body pb-0">
        <form [formGroup]="filterForm" (ngSubmit)="onSubmitFilterForm()">
          <div class="row pt-4 pr-4 pl-4">
            <div class="col-md-10">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field (click)="fromPicker.open()">
                      <mat-label>Fecha inicial</mat-label>
                      <input matInput [matDatepicker]="fromPicker" formControlName="from">
                      <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                      <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field (click)="toPicker.open()">
                      <mat-label>Fecha final</mat-label>
                      <input matInput [matDatepicker]="toPicker" formControlName="to">
                      <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                      <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>Yate</mat-label>
                      <mat-select formControlName="yacht">
                        <mat-option [value]="null">Todos</mat-option>
                        <mat-option *ngFor="let y of yachts" [value]="y.id">{{y.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>Estatus</mat-label>
                      <mat-select formControlName="status">
                        <mat-option [value]="null">Todos</mat-option>
                        <mat-option *ngFor="let s of status" [value]="s.id">{{s.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mb-4">
              <button type="submit" class="btn btn-block btn-primary mt-3">
                APLICAR FILTRO
              </button>
              <button type="button" (click)="onClearFilter()" [disabled]="!filtered" class="btn btn-block btn-outline-secondary mt-3">
                BORRAR
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="table-responsive">
        <table class="table table-hover" mat-table recycleRows [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
            <td mat-cell *matCellDef="let row" class=""> {{row.id}} </td>
          </ng-container>

          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CLIENTE</th>
            <td mat-cell *matCellDef="let row"> {{row.client}}</td>
          </ng-container>

          <ng-container matColumnDef="yacht">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="cen"> YATE</th>
            <td mat-cell *matCellDef="let row" class=""> {{row.yacht}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA</th>
            <td mat-cell *matCellDef="let row" class=""> {{row.date|date:'dd/MM/y'}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTATUS</th>
            <td mat-cell *matCellDef="let row" class="">
              <label class="badge"
                     [ngClass]="{
                           'badge-light-primary': row.status === 'FINALIZADO',
                           'badge-light-success': row.status === 'CONFIRMADA',
                           'badge-light-warning': row.status === 'PENDIENTE',
                           'badge-light-danger': row.status === 'CANCELADA',
                           'badge-light-secondary': row.status === 'NO SHOW'
                           }">
                {{row.status}}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> ACCIÓN</th>
            <td mat-cell *matCellDef="let row">
              <a [routerLink]="[row.id]" target="_blank">
                <i class="icon feather icon-edit f-w-600 f-16 m-r-15 text-c-green"></i>
              </a>
              <a href="https://api.whatsapp.com/send?phone={{row.phone}}" target="_blank">
                <i class="icon feather icon-message-circle f-w-600 f-16 m-r-15 text-c-green"></i>
              </a>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron resultados</td>
          </tr>
        </table>
        <mat-paginator pageSize="10" [hidePageSize]="true" showFirstLastButtons></mat-paginator>

      </div>
    </div>
  </div>
</div>
<!-- //YM - LAST BOOKINGS -->

