<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">REGISTROS DE SEGURO</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <!-- YM - LAST BOOKINGS -->
  <div class="col-lg-12">
    <div class="card table-card latest-activity-card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h5>Registros</h5>
          </div>
          <div class="col">
            <form [formGroup]="filterForm" (ngSubmit)="onDownloadFile()">
              <div class="row">
                <div class="col">
                  <mat-form-field (click)="fromPicker.open()">
                    <mat-label>Fecha inicial</mat-label>
                    <input matInput [matDatepicker]="fromPicker" formControlName="from">
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field (click)="toPicker.open()">
                    <mat-label>Fecha final</mat-label>
                    <input matInput [matDatepicker]="toPicker" formControlName="to">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col">
                  <button type="submit" class="btn btn-info btn-sm float-right" [disabled]="filterForm.invalid"><i class="feather icon-download"></i> DESCARGAR</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="example-container mat-elevation-z8">
          <div class="example-table-container">

            <table mat-table [dataSource]="data" class="table table-hover" matSort
                   matSortActive="id" matSortDisableClear matSortDirection="desc">
              <!-- Number Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
              </ng-container>

              <!-- Title Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EMAIL</th>
                <td mat-cell *matCellDef="let row">{{row.email}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="birthdate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA NACIMIENTO</th>
                <td mat-cell *matCellDef="let row">{{row.birthdate}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TELÉFONO</th>
                <td mat-cell *matCellDef="let row">{{row.phone}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="reservation_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA SALIDA</th>
                <td mat-cell *matCellDef="let row">{{row.reservation_date}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="reservation_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> HORA SALIDA</th>
                <td mat-cell *matCellDef="let row">{{row.reservation_time}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="yacht_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> YATE</th>
                <td mat-cell *matCellDef="let row">{{row.yacht?.name}}</td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="accept">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ACEPTÓ</th>
                <td mat-cell *matCellDef="let row">{{row.accept === 1 ? 'SÍ' : 'NO'}}</td>
              </ng-container>

              <!-- Created Column -->
              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> REGISTRO</th>
                <td mat-cell *matCellDef="let row">{{row.created_at | date:'dd/MM/y hh:mm:ss a'}}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> ACCIONES</th>
                <td mat-cell *matCellDef="let row">
                  <a *ngIf="canEdit" (click)="onDeleteForm(row.id)" style="cursor: pointer;" title="Eliminar">
                    <i class="icon feather icon-trash f-w-600 f-16 m-r-15 text-c-red"></i>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <mat-paginator [length]="resultsLength" [pageSize]="10" aria-label=""></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <!-- //YM - LAST BOOKINGS -->
</div>
<!-- //YM - DASHBOARD -->
