export default class Reservation {
  public id?: number;
  public status_id?: number;
  public payment_status_id?: number;
  public source_id?: any;
  public reference?: any;
  public folio?: string;
  public client_id?: number;
  public reservation_date?: string;
  public reservation_time?: string;
  public yacht_id?: number;
  public yacht_name?: YachtName;
  public hours?: number;
  public adults?: number;
  public children?: number;
  public total_passengers?: number;
  public dock_fee?: number;
  public reservation_fee?: number;
  public reserved_hours_price?: number;
  public price_per_extra_hour?: number;
  public extra_hours?: number;
  public extra_hours_price?: number;
  public reservation_price?: number;
  public promocode_id?: number;
  public promocode_discount?: number;
  public total_price?: number;
  public extras_price?: number;
  public final_price?: number;
  public paid?: number;
  public pending?: number;
  public created_at?: string;
  public updated_at?: string;
  public yacht?: Yacht;
  public status?: Status;
  public source?: Source;
  public client?: Client;
  public client_name?: ClientName;
  public promocode?: Promocode;
  public extras?: Extra[];
  public hash?: string;
  public created_by?: CreatedBy;
  public sales_fee?: number;
  public final?: number;
  public with_invoice?: number;
  public invoice_fees?: number;
  public invoice_no?: string;
  public client_rfc?: string;
}

export class CreatedBy {
  name?: string;
  username?: string;
}

export class Extra {
  public id?: number;
  public name?: string;
  public price?: number;
}

export class Status {
  public id?: number;
  public name?: string;
}

export class Client {
  public id?: number;
  public name?: string;
  public last_name?: string;
  public email?: string;
  public phone?: string;
  public created_at?: string;
  public updated_at?: string;
}

export class ClientName {
  public id?: number;
  public name?: string;
  public last_name?: string;
  public phone?: number;
}

export class YachtName {
  public id?: number;
  public name?: string;
}

export class Promocode {
  public id?: number;
  public name?: string;
  public code?: string;
  public type_id?: number;
  public type?: PromocodeType;
  public discount?: number;
  public is_active?: boolean;
}

export class PromocodeType {
  public id?: number;
  public name?: string;
}

export class Yacht {
  public id?: number;
  public name?: string;
  public is_active_web?: number;
  public is_active_admin?: number;
  public is_active_partners?: number;
  public active_str?: string;
  public prices?: YachtHourPrice[];
}

export class YachtHourPrice {
  public id?: number;
  public yacht_id?: number;
  public hours?: number;
  public price?: number;
  public online_price?: number;
  public usd_online_price?: number;
}

export class Payment {
  public id?: number;
  public reservation_id?: number;
  public type_id?: number;
  public method_id?: number;
  public amount?: number;
  public mxn_amount?: number;
  public fees?: number;
  public net_fees?: number;
  public sales_fees?: number;
  public paid_at?: string;
  public reference?: string;
  public type?: PaymentType;
  public method?: PaymentMethod;
  public count?: number;
  public isFee?: number;
  public final?: number;
  public currency?: any;
  public currency_id?: any;
}

export class PaymentType {
  public id: number;
  public name: string;
}

export class PaymentMethod {
  public id: number;
  public name: string;
}

export class Source {
  public id: number;
  public name: string;
}

export class Comment {
  public id: number;
  public comment: string;
  public created_at: string;
}
