import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReservationStatusService {
  private url = environment.apiUrl + 'reservation-status';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllStatus() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  getAllPaymentStatus() {
    return this.httpClient.get(environment.apiUrl + 'reservation-payment-status')
      .toPromise();
  }
}
