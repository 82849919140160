import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { saveAs } from 'file-saver';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {
  private url = environment.apiUrl + 'reservations';
  constructor(
    private httpClient: HttpClient
  ) { }

  registerReservation(body: any) {
    return this.httpClient.post(this.url + `/dashboard`, body)
      .toPromise();
  }

  getAllReservations(params = {}) {
    return this.httpClient.get(this.url, {params})
      .pipe(
        map((response: any) => {
          const reservations = [];
          for (const r of response['data']) {
            reservations.push({
              id: r.id,
              client: r.client_name.name + ' ' + r.client_name.last_name,
              phone: r.client_name.phone,
              yacht: r.yacht_name.name,
              date: r.reservation_date,
              status: r.status.name,
            });
          }

          return reservations;
        })
      )
      .toPromise();
  }

  getAllReservationsCount() {
    return this.httpClient.get(this.url + '/counts')
      .toPromise();
  }

  getLastReservations() {
    return this.httpClient.get(this.url + '/last')
      .toPromise();
  }

  getReservationById(reservationId: string) {
    return this.httpClient.get(this.url + `/${reservationId}`)
      .toPromise();
  }

  getCommentsByReservationId(reservationId: string) {
    return this.httpClient.get(this.url + `/${reservationId}/comments`)
      .toPromise();
  }

  createCommentByReservationId(reservationId: string, comment: string) {
    return this.httpClient.post(this.url + `/${reservationId}/comments`, {comment})
      .toPromise();
  }

  getReservationsForCalendar() {
    return this.httpClient.get(this.url + `/calendar`)
      .toPromise();
  }

  updateReservation(reservationId: string, body: any) {
    return this.httpClient.put(this.url + `/${reservationId}/reservation`, body)
      .toPromise();
  }

  updatePrices(reservationId: string, body: any) {
    return this.httpClient.put(this.url + `/${reservationId}/prices`, body)
      .toPromise();
  }

  updateSource(reservationId: string, body: any) {
    return this.httpClient.put(this.url + `/${reservationId}/source`, body)
      .toPromise();
  }

  getReport(params: any) {
    return this.httpClient.get(this.url + `/report`,
      { observe: 'response', responseType: 'arraybuffer', params})
      .toPromise()
      .then(response => {
        const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
        const blob = new Blob([response.body], {type: response.type.toString()});
        saveAs(blob, filename);
      });
  }
}
