import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Membership, {MembershipPlan, MembershipStatus} from '../../models/membership';
import {MembershipPlansService} from '../../services/membership-plans.service';
import {PaymentType, Source} from '../../models/reservation';
import {PaymentTypesService} from '../../services/payment-types.service';
import {MembershipsService} from '../../services/memberships.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MembershipStatusService} from '../../services/membership-status.service';
import {MembershipPaymentsService} from '../../services/membership-payments.service';
import {ReservationSourcesService} from '../../services/reservation-sources.service';
import {MembershipCommentsService} from '../../services/membership-comments.service';

@Component({
  selector: 'app-annual-membership-detail',
  templateUrl: './annual-membership-detail.component.html',
  styles: [
  ]
})
export class AnnualMembershipDetailComponent implements OnInit {

  membershipId: string;
  membership: Membership = new Membership();
  plans: MembershipPlan[] = [];
  paymentTypes: PaymentType[] = [];
  status: MembershipStatus[] = [];
  sources: Source[] = [];

  memberForm: UntypedFormGroup;
  membershipForm: UntypedFormGroup;
  paymentForm: UntypedFormGroup;
  sourceForm: UntypedFormGroup;
  commentControl = new UntypedFormControl(null, Validators.required);
  canEdit: boolean;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private membershipPlansService: MembershipPlansService,
    private paymentTypesService: PaymentTypesService,
    private membershipsService: MembershipsService,
    private membershipStatusService: MembershipStatusService,
    private membershipPaymentsService: MembershipPaymentsService,
    private reservationSourcesService: ReservationSourcesService,
    private membershipCommentsService: MembershipCommentsService,
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this.buildForms();
  }

  ngOnInit(): void {
    this.membershipId = this.route.snapshot.paramMap.get('id');
    const promises = [
      this.membershipPlansService.getAllMembreshipPlans(),
      this.paymentTypesService.getAllPaymentTypes(),
      this.membershipsService.getMembershipById(this.membershipId),
      this.membershipStatusService.getAllMembreshipStatus(),
      this.reservationSourcesService.getAllSources(),
    ];

    Promise.all(promises).then(responses => {
      this.plans = responses[0]['data'];
      this.paymentTypes = responses[1]['data'];
      this.membership = responses[2]['data'];
      this.status = responses[3]['data'];
      this.sources = responses[4]['data'];

      this.setFormsData();

    });
  }

  submitComment() {
    if (this.commentControl.valid) {
      const comment = this.commentControl.value;
      this.membershipCommentsService.createComment(this.membershipId, {comment}).then((response) => {
        this.commentControl.reset();
        this.membership.comments = response['data'];
      });
    }
  }

  submitMemberForm() {
    if (this.memberForm.dirty && this.memberForm.valid) {
      this.membershipsService.updateMemberByMembershipId(this.membershipId, this.memberForm.value).then(() => {
      });
    }
  }

  submitMembershipForm() {
    if (this.membershipForm.dirty && this.membershipForm.valid) {
      this.membershipsService.updateMembershipById(this.membershipId, this.membershipForm.value).then(() => {
      });
    }
  }

  submitPaymentForm() {
    if (this.paymentForm.valid) {

      const body = {
        amount: this.paymentForm.value.amount,
        type: this.paymentForm.value.type,
        date: this.paymentForm.value.date.toISOString().split('T')[0],
      };
      this.membershipPaymentsService.createPayment(this.membershipId, body).then((response) => {
        this.membership.payments = response['data'];
        this.paymentForm.reset();
      });
    }
  }

  submitSourceForm() {
    if (this.sourceForm.valid) {

      this.membershipsService.updateSourceByMembershipId(this.membershipId, this.sourceForm.value).then(() => {
      });
    }
  }

  private buildForms() {
    this.memberForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
    });

    this.membershipForm = this.formBuilder.group({
      plan: [null, Validators.required],
      activationDate: [null],
      expirationDate: [null],
      hours: [null, Validators.required],
      status: [null, Validators.required],
    });

    this.paymentForm = this.formBuilder.group({
      amount: [null],
      type: [null],
      date: [null],
    });

    this.sourceForm = this.formBuilder.group({
      source: [null, Validators.required],
      reference: [null, Validators.required],
    });

    if (!this.canEdit) {
      this.memberForm.disable();
      this.membershipForm.disable();
      this.paymentForm.disable();
      this.sourceForm.disable();
      this.commentControl.disable();
    }
  }

  private setFormsData() {
    this.memberForm.setValue({
      firstName: this.membership.member.first_name,
      lastName: this.membership.member.last_name,
      phone: this.membership.member.phone,
      email: this.membership.member.email,
    });

    this.membershipForm.setValue({
      plan: this.membership.plan_id,
      activationDate: this.membership.activation_date,
      expirationDate: this.membership.expiration_date,
      hours: this.membership.hours,
      status: this.membership.status_id,
    });

    this.sourceForm.setValue({
      source: this.membership.source_id,
      reference: this.membership.reference
    });
  }

}
