import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import Reservation, {Extra, Payment, PaymentType, Promocode, Source, Status, Yacht, Comment, PaymentMethod} from '../../models/reservation';
import {ReservationsService} from '../../services/reservations.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {YachtService} from '../../services/yacht.service';
import {ReservationStatusService} from '../../services/reservation-status.service';
import {ReservationExtrasService} from '../../services/reservation-extras.service';
import {PaymentsService} from '../../services/payments.service';
import {PaymentTypesService} from '../../services/payment-types.service';
import {ReservationSourcesService} from '../../services/reservation-sources.service';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import HourPrice from '../../models/hour-price';
import {PromocodesService} from '../../services/promocodes.service';
import {HourPricesService} from '../../services/hour-prices.service';
import {AuthService} from '../../services/auth.service';
import {PaymentMethodsService} from '../../services/payment-methods.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';
import {CurrencyService} from '../../services/currency.service';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styles: [
    '.mat-input-element:disabled { color: rgba(0,0,0,1);}'
  ]
})
export class ReservationDetailComponent implements OnInit {
  @ViewChild('errorSwal') errorSwal: SwalComponent;
  @ViewChild('exceedsCheckinSwal') exceedsCheckinSwal: SwalComponent;
  @ViewChild('pendingSwal') pendingSwal: SwalComponent;
  @ViewChild('deleteSwal') deleteSwal: SwalComponent;
  @ViewChild('yachtSwal') yachtSwal: SwalComponent;

  @ViewChild('editPaymentDialog') editPaymentDialog: TemplateRef<any>;

  dialogConfig: MatDialogConfig = {
    // minWidth: 800,
    closeOnNavigation: true,
  };
  detailDialogRef: MatDialogRef<any> = null;
  selectedPaymentForm: UntypedFormGroup;
  selectedPayment: Payment;

  canUpdateSource = false;

  reservationId: string;
  reservation = new Reservation();
  reservationTime = null;
  selectedYacht = null;
  payments: Payment[] = [];
  comments: Comment[] = [];

  reservationInfoForm: UntypedFormGroup;
  paymentForm: UntypedFormGroup;
  sourceForm: UntypedFormGroup;
  commentCtrl = new UntypedFormControl(null, Validators.required);
  yachts: Yacht[] = [];
  status: Status[] = [];
  paymentStatus: Status[] = [];
  extras: Extra[] = [];
  extrasArr = new UntypedFormArray([]);
  paymentTypes: PaymentType[] = [];
  paymentMethods: PaymentMethod[] = [];
  sources: Source[] = [];
  promocodes: Promocode[] = [];
  prices: HourPrice[] = [];
  currencies = [
    {id: 1, name: 'MXN'},
    {id: 2, name: 'USD'},
    {id: 3, name: 'EUR'},
  ];
  canEdit: boolean;
  canEditPayments: boolean;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private reservationsService: ReservationsService,
    private yachtsService: YachtService,
    private reservationStatusService: ReservationStatusService,
    private extrasService: ReservationExtrasService,
    private paymentsService: PaymentsService,
    private paymentTypesService: PaymentTypesService,
    private paymentMethodsService: PaymentMethodsService,
    private sourcesService: ReservationSourcesService,
    private promocodesService: PromocodesService,
    private hourPricesService: HourPricesService,
    private authService: AuthService,
    private currencyService: CurrencyService,
    public dialog: MatDialog,
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role.find(r => r.moduleId === 1).role === 1;
      this.canEditPayments = data.role.find(r => r.moduleId === 2).role === 1;
    }).unsubscribe();
    this._buildForms();
  }

  ngOnInit() {
    this.reservationId = this.route.snapshot.paramMap.get('id');

    const promises = [
      this.reservationsService.getReservationById(this.reservationId),
      this.yachtsService.getAllYachts(),
      this.reservationStatusService.getAllStatus(),
      this.extrasService.getAllExtras(),
      this.paymentsService.getPaymentsByReservationId(this.reservationId),
      this.paymentTypesService.getAllPaymentTypes(),
      this.sourcesService.getAllSources(),
      this.promocodesService.getAllPromocodes(),
      this.reservationsService.getCommentsByReservationId(this.reservationId),
      this.paymentMethodsService.getAllPaymentMethods(),
      this.reservationStatusService.getAllPaymentStatus(),
    ];

    Promise.all(promises)
      .then(async resp => {
        this.reservation = resp[0]['data'];
        this.yachts = resp[1];
        this.status = resp[2]['data'];
        this.extras = resp[3]['data'];
        this.payments = resp[4]['data'];
        this.paymentTypes = resp[5]['data'];
        this.sources = resp[6]['data'];
        this.promocodes = resp[7]['data'];
        this.comments = resp[8]['data'];
        this.paymentMethods = resp[9]['data'];
        this.paymentStatus = resp[10]['data'];

        await this._setFormsData();
      });
  }

  onChangeInvoice($event: MatRadioChange) {
    if ($event.value === 1) {
      this.reservationInfoForm.get('invoiceFees').enable();
      this.reservationInfoForm.get('invoiceNo').enable();
      this.reservationInfoForm.get('clientRfc').enable();
    } else {
      this.reservationInfoForm.get('invoiceFees').disable();
      this.reservationInfoForm.get('invoiceNo').disable();
      this.reservationInfoForm.get('clientRfc').disable();
    }
    this.updateFinalPrice();
  }

  onSelectPayment(payment) {
    this._setFormData(payment);
    this._openViewDetailDialog();
  }

  onChangePercentage(form: UntypedFormGroup) {
    const percCtrl = form.get('fees');
    const netCtrl = form.get('net_fees');
    const amountCtrl = form.get('amount');

    if (percCtrl.value >= 0) {
      netCtrl.setValue((percCtrl.value * amountCtrl.value / 100).toFixed(2));
    } else {
      netCtrl.setValue(0);
    }
  }

  onChangeNet(form: UntypedFormGroup) {
    const percCtrl = form.get('fees');
    const netCtrl = form.get('net_fees');
    const amountCtrl = form.get('amount');

    if (netCtrl.value >= 0) {
      percCtrl.setValue((netCtrl.value * 100 / amountCtrl.value).toFixed(2));
    } else {
      percCtrl.setValue(0);
    }
  }

  private _openViewDetailDialog() {
    this.detailDialogRef = this.dialog.open(this.editPaymentDialog, this.dialogConfig);
  }

  private _setFormData(payment: Payment) {
    const amountCtrl = this.selectedPaymentForm.get('amount') as UntypedFormControl;
    const exchangeCtrl = this.selectedPaymentForm.get('exchange') as UntypedFormControl;
    exchangeCtrl.setValue(payment.mxn_amount / payment.amount);
    amountCtrl.setValue(payment.amount);

    this.selectedPaymentForm.patchValue({
      // amount: payment.mxn_amount,
      type: payment.type?.id,
      method: payment.method?.id,
      currency: payment.currency_id ? payment.currency_id : 1,
      date: payment.paid_at.split(' ')[0],
      reference: payment.reference,
      fees: payment.fees,
      net_fees: payment.net_fees,
    });

    this.selectedPayment = payment;
  }

  updatePayment() {
    if (this.selectedPaymentForm.valid) {
      this.paymentsService.updatePayment(this.selectedPayment.id, this.selectedPaymentForm.value).then(
        () => {
          this.paymentsService.getPaymentsByReservationId(this.reservationId).then(response => {
            this.payments = response['data'];
            this.detailDialogRef.close();
          });
        }
      );
    }
  }

  async deletePayment() {
    if (this.selectedPayment) {
      await this.paymentsService.deletePayment(this.selectedPayment.id);
      this.paymentsService.getPaymentsByReservationId(this.reservationId).then(response => {
        this.payments = response['data'];
        // this.detailDialogRef.close();
      });
    }
  }

  updateExtraPriceField(index: number) {
    const group = this.extrasArr.at(index) as UntypedFormGroup;
    const priceCtrl = group.get('price');

    if (group.get('selected').value) {
      priceCtrl.setValidators(Validators.required);
      if (priceCtrl.value === null) {
        priceCtrl.setValue(this.extras[index].price);
      }
      priceCtrl.enable();
    } else {
      priceCtrl.clearValidators();
      priceCtrl.disable();
    }

    this.updateExtrasPrice();
  }

  async updatePrices() {
    if (this.reservationInfoForm.value.yacht !== this.selectedYacht) {
      const resp = await this.yachtSwal.fire();
      if (resp.isConfirmed) {
        this.prices = (await this.hourPricesService.getHourPricesByYachtId(this.reservationInfoForm.value.yacht))['data'];
      } else {
        this.selectedYacht = this.reservationInfoForm.value.yacht;
        return;
      }
    }

    this.reservationInfoForm.get('hours').enable();
    if (this.reservationInfoForm.get('hours').value !== null) {
      this.updateHoursPrice();
    }
    this.selectedYacht = this.reservationInfoForm.value.yacht;
  }

  updateHoursPrice() {
    const hoursCtrl = this.reservationInfoForm.get('hours');
    const hoursPriceCtrl = this.reservationInfoForm.get('hoursPrice');
    const hoursPrice = this.prices.find((p) => {
      return p.hours === hoursCtrl.value;
    });

    hoursPriceCtrl.setValue(hoursPrice.price);
    hoursPriceCtrl.enable();
    this.updateReservationPrice();
  }

  updateDockFee() {
    const adultsFee = this.reservationInfoForm.get('adults').value * 150;
    const childrenFee = this.reservationInfoForm.get('children').value * 150;
    const dockFeeCtrl = this.reservationInfoForm.get('dockFee');

    dockFeeCtrl.setValue(adultsFee + childrenFee);
    dockFeeCtrl.enable();
    this.updateReservationPrice();
  }

  updateExtraHoursPrice() {
    const pricePerExtraHourCtrl = this.reservationInfoForm.get('pricePerExtraHour');
    const extraHoursCtrl = this.reservationInfoForm.get('extraHours');
    const extraHoursPriceCtrl = this.reservationInfoForm.get('extraHoursPrice');

    extraHoursPriceCtrl.setValue(pricePerExtraHourCtrl.value * extraHoursCtrl.value);
    this.updateReservationPrice();
  }

  updateReservationPrice() {
    const dockFeeCtrl = this.reservationInfoForm.get('dockFee');
    const reservFeeCtrl = this.reservationInfoForm.get('reservationFee');
    const hoursPriceCtrl = this.reservationInfoForm.get('hoursPrice');
    const extraHoursPriceCtrl = this.reservationInfoForm.get('extraHoursPrice');
    const reservPriceCtrl = this.reservationInfoForm.get('reservationPrice');

    reservPriceCtrl.setValue(dockFeeCtrl.value + reservFeeCtrl.value + hoursPriceCtrl.value + extraHoursPriceCtrl.value);
    this.updatePromocodeDiscount();
  }

  updatePromocodeDiscount() {
    const codeCtrl = this.reservationInfoForm.get('promocode');
    const discountCtrl = this.reservationInfoForm.get('promocodeDiscount');

    if (codeCtrl.value === null) {
      discountCtrl.setValue(0);
    } else {
      const code = this.promocodes.find((c) => c.id === codeCtrl.value);
      const hoursPrice = this.reservationInfoForm.get('hoursPrice').value;
      let discount = 0;

      if (code) {
        if (code.type.id === 1) {
          discount = hoursPrice * (code.discount / 100);
        } else if (code.type.id === 2) {
          discount = code.discount;
        }
      }
      discountCtrl.setValue(discount);
    }

    this.updateTotalPrice();
  }

  updateTotalPrice() {
    const reservPriceCtrl = this.reservationInfoForm.get('reservationPrice');
    const discountCtrl = this.reservationInfoForm.get('promocodeDiscount');
    const totalPriceCtrl = this.reservationInfoForm.get('totalPrice');

    totalPriceCtrl.setValue(reservPriceCtrl.value - discountCtrl.value);
    this.updateFinalPrice();
  }

  updateExtrasPrice() {
    const price = this.extrasArr.getRawValue()
      .filter(e => e.selected)
      .reduce((acc, e) => {
        return acc + e.price;
      }, 0);

    this.reservationInfoForm.get('extrasPrice').setValue(price);
    this.updateFinalPrice();
  }

  updateFinalPrice() {
    const salesFeeCtrl = this.reservationInfoForm.get('salesFee');
    const totalPriceCtrl = this.reservationInfoForm.get('totalPrice');
    const extrasPriceCtrl = this.reservationInfoForm.get('extrasPrice');
    const finalPriceCtrl = this.reservationInfoForm.get('finalPrice');

    let total = totalPriceCtrl.value + extrasPriceCtrl.value + salesFeeCtrl.value;
    if (this.reservationInfoForm.get('withInvoice').value === 1) {
      let fee = this.reservationInfoForm.get('invoiceFees').value;
      fee = fee >= 0 ? (fee / 100) : 0.16;
      total = total * (1 + fee);
    }

    finalPriceCtrl.setValue(total);
  }

  /*onSelectMethod(form: FormGroup) {
    const currencyCtrl = form.get('currency');

    if (form.value.method === 7) {
      currencyCtrl.enable();
    } else {
      currencyCtrl.disable();
    }
  }*/

  onSelectCurrency(form: UntypedFormGroup) {
    const exchangeCtrl = form.get('exchange');
    const currency = form.value.currency;
    exchangeCtrl.setValue(1);

    if (currency !== 1) {
      this.currencyService.getLastCurrencyPrice(currency).then(response => {
        // exchangeCtrl.enable();
        exchangeCtrl.setValue(response['data']);
      });
    }
  }

  async submitReservationInfo() {
    if (this.reservationInfoForm.valid) {

      /*if (!this._isValidCheckin()) {
        return;
      }*/

      if (this.reservationInfoForm.value.status === 6 && this.reservation.pending > 0) {
        const resp = await this.pendingSwal.fire();
        if (!resp.isConfirmed) {
          return;
        }
      }

      const body = this._getFormattedReservationBody();
      this.reservationsService.updateReservation(this.reservationId, body).then(async resp => {
        this.reservation = resp['data'];
        await this._setFormsData();
        this.reservationInfoForm.markAsPristine();
      }).catch((err) => {
        this.errorSwal.fire();
      });
    }
  }

  submitPaymentForm() {
    if (this.paymentForm.valid) {
      const body = this.paymentForm.value;
      this.paymentsService.registerPayment(this.reservationId, body).then(async response => {
        this.payments = (await this.paymentsService.getPaymentsByReservationId(this.reservationId))['data'];
        this.paymentForm.reset();
        this.paymentForm.markAsPristine();
        this.paymentForm.markAsUntouched();
        this.paymentForm.updateValueAndValidity();
        this.reservationsService.getReservationById(this.reservationId).then(async res => {
          this.reservation = res['data'];
          await this._setFormsData();
        });
      });
    }
  }

  submitSourceForm() {
    if (this.sourceForm.valid) {
      const body = this.sourceForm.value;
      this.reservationsService.updateSource(this.reservationId, body).then(async resp => {
        this.reservation = resp['data'];
        await this._setFormsData();
        this.sourceForm.markAsPristine();
      });
    }
  }

  submitComment() {
    if (this.commentCtrl.valid) {
      this.reservationsService.createCommentByReservationId(this.reservationId, this.commentCtrl.value)
        .then(async () => {
          this.comments = (await this.reservationsService.getCommentsByReservationId(this.reservationId))['data'];

          this.commentCtrl.reset();
          this.commentCtrl.markAsPristine();
        });
    }
  }

  private _buildForms() {
    this.reservationInfoForm = this.formBuilder.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      folio: [null],
      yacht: [null, Validators.required],
      date: [null, Validators.required],
      hours: [null, Validators.required],
      checkin: [null, Validators.required],
      adults: [0, [Validators.required, Validators.min(0)]],
      children: [0, [Validators.required, Validators.min(0)]],
      status: [null, Validators.required],
      paymentStatus: [null, Validators.required],
      extras: new UntypedFormArray([]),
      dockFee: [0, [Validators.min(0)]],
      withInvoice: [0],
      invoiceFees: [{value: 16, disabled: true}, [Validators.required, Validators.min(0)]],
      invoiceNo: [{value: null, disabled: true}],
      clientRfc: [{value: null, disabled: true}],
      reservationFee: [0, [Validators.required, Validators.min(0)]],
      hoursPrice: [null, [Validators.required, Validators.min(0)]],
      pricePerExtraHour: [1500, [Validators.required, Validators.min(0)]],
      extraHours: [0, [Validators.required, Validators.min(0)]],
      extraHoursPrice: [0, [Validators.required, Validators.min(0)]],
      reservationPrice: [null, [Validators.required, Validators.min(0)]],
      promocode: [null],
      promocodeDiscount: [0, [Validators.required, Validators.min(0)]],
      totalPrice: [null, [Validators.required, Validators.min(0)]],
      extrasPrice: [0, [Validators.required, Validators.min(0)]],
      salesFee: [0, [Validators.required, Validators.min(0)]],
      finalPrice: [null, Validators.required],
    });

    this.extrasArr = this.reservationInfoForm.get('extras') as UntypedFormArray;

    this.paymentForm = this.formBuilder.group({
      amount: [null, Validators.required],
      type: [null, Validators.required],
      method: [null, Validators.required],
      currency: [null, Validators.required],
      exchange: [1],
      date: [null, Validators.required],
      reference: [null],
      fees: [0],
      net_fees: [0],
    });

    this.sourceForm = this.formBuilder.group({
      source: [null, Validators.required],
      reference: [null],
    });

    this.selectedPaymentForm = this.formBuilder.group({
      amount: [null, Validators.required],
      type: [null, Validators.required],
      method: [null, Validators.required],
      currency: [null, Validators.required],
      exchange: [1],
      date: [null, Validators.required],
      reference: [null],
      fees: [0],
      net_fees: [0],
      salesFees: [0],
    });

    if (!this.canEdit) {
      this.reservationInfoForm.disable();
      this.sourceForm.disable();
      this.commentCtrl.disable();
    }

    if (!this.canEditPayments) {
      this.paymentForm.disable();
      this.selectedPaymentForm.disable();
    }
  }

  private _canUpdateSource() {
    if (this.reservation.source && this.reservation.source.name === 'REDES SOCIALES') {
      this.sourceForm.disable();
      this.canUpdateSource = false;
    } else {
      this.canUpdateSource = true;
    }
  }

  private _getFormattedReservationBody() {
    const extras = this.extrasArr.getRawValue().filter(e => e.selected);
    return {
      client: {
        clientId: this.reservation.client_id,
        name: this.reservationInfoForm.value.name,
        lastName: this.reservationInfoForm.value.lastName,
        email: this.reservationInfoForm.value.email,
        phone: this.reservationInfoForm.value.phone,
      },
      reservation: {
        folio: this.reservationInfoForm.value.folio,
        yachtId: this.reservationInfoForm.value.yacht,
        date: this.reservationInfoForm.value.date.toISOString().split('T')[0],
        hours: this.reservationInfoForm.value.hours,
        checkin: this.reservationInfoForm.value.checkin,
        adults: this.reservationInfoForm.value.adults,
        children: this.reservationInfoForm.value.children,
        statusId: this.reservationInfoForm.value.status,
        paymentStatusId: this.reservationInfoForm.value.paymentStatus,
        withInvoice: this.reservationInfoForm.getRawValue().withInvoice,
        invoiceFees: this.reservationInfoForm.getRawValue().invoiceFees,
        invoiceNo: this.reservationInfoForm.getRawValue().invoiceNo,
        clientRfc: this.reservationInfoForm.getRawValue().clientRfc,
        dockFee: this.reservationInfoForm.value.dockFee,
        reservationFee: this.reservationInfoForm.value.reservationFee,
        hoursPrice: this.reservationInfoForm.value.hoursPrice,
        pricePerExtraHour: this.reservationInfoForm.value.pricePerExtraHour,
        extraHours: this.reservationInfoForm.value.extraHours,
        extraHoursPrice: this.reservationInfoForm.value.extraHoursPrice,
        reservationPrice: this.reservationInfoForm.value.reservationPrice,
        promocode: this.reservationInfoForm.value.promocode,
        promocodeDiscount: this.reservationInfoForm.value.promocodeDiscount,
        totalPrice: this.reservationInfoForm.value.totalPrice,
        extrasPrice: this.reservationInfoForm.value.extrasPrice,
        salesFee: this.reservationInfoForm.value.salesFee,
        finalPrice: this.reservationInfoForm.value.finalPrice,
      },
      extras
    };
  }

  private async _setFormsData() {

    this.prices = (await this.hourPricesService.getHourPricesByYachtId(this.reservation.yacht_id))['data'];

    this.extrasArr.clear();
    for (const extra of this.extras) {
      const rExtra = this.reservation.extras.find(e => e.id === extra.id);
      let selected = false;
      let price = extra.price;
      if (rExtra !== undefined) {
        selected = true;
        price = rExtra.price;
      }

      const group = this.formBuilder.group({
        id: [extra.id],
        price: [{value: price, disabled: !selected}],
        selected: [selected],
      });

      if (!this.canEdit) {
        group.disable();
      }
      this.extrasArr.push(group);
    }

    this.reservationInfoForm.patchValue({
      name: this.reservation.client.name,
      lastName: this.reservation.client.last_name,
      phone: this.reservation.client.phone,
      email: this.reservation.client.email,
      folio: this.reservation.folio,
      yacht: this.reservation.yacht_id,
      date: new Date(this.reservation.reservation_date + 'T00:00:00'),
      hours: this.reservation.hours,
      checkin: this.reservation.reservation_time,
      adults: this.reservation.adults,
      children: this.reservation.children,
      status: this.reservation.status_id,
      paymentStatus: this.reservation.payment_status_id,
      withInvoice: Number(!!this.reservation.with_invoice),
      invoiceFees: this.reservation.invoice_fees ?? 16,
      invoiceNo: this.reservation.invoice_no,
      clientRfc: this.reservation.client_rfc,
      dockFee: this.reservation.dock_fee,
      reservationFee: this.reservation.reservation_fee,
      hoursPrice: this.reservation.reserved_hours_price,
      pricePerExtraHour: this.reservation.price_per_extra_hour ?? 0,
      extraHours: this.reservation.extra_hours ?? 0,
      extraHoursPrice: this.reservation.extra_hours_price ?? 0,
      reservationPrice: this.reservation.reservation_price,
      promocode: this.reservation.promocode_id,
      promocodeDiscount: this.reservation.promocode_discount,
      totalPrice: this.reservation.total_price,
      extrasPrice: this.reservation.extras_price,
      salesFee: this.reservation.sales_fee,
      finalPrice: this.reservation.final_price,
    });
    this.selectedYacht = this.reservation.yacht.id;

    this.reservationTime = this.reservation.reservation_date + 'T' + this.reservation.reservation_time;

    this.sourceForm.setValue({
      source: this.reservation.source_id,
      reference: this.reservation.reference
    });

    /*if (!this.authService.canEdit()) {
      this.reservationInfoForm.disable();
      this.paymentForm.disable();
      this.sourceForm.disable();
    }*/

    this._canUpdateSource();
  }

  private _isValidCheckin() {
    const checkin = Number(this.reservationInfoForm.value.checkin.substring(0, 2));
    const hours = this.reservationInfoForm.value.hours;

    if (checkin + hours > 22) {
      this.exceedsCheckinSwal.fire();
      return false;
    }

    return true;
  }

}
