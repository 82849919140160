import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class MovementsService {
  private url = environment.apiUrl + 'movements';
  constructor(
    private httpClient: HttpClient
  ) { }

  createMovement(body: any) {
    return this.httpClient.post(this.url, body)
      .toPromise();
  }

  updateMovement(movementId, body: any) {
    return this.httpClient.post(this.url + `/${movementId}`, body)
      .toPromise();
  }

  deleteMovementById(movementId: any) {
    return this.httpClient.delete(this.url + `/${movementId}`)
      .toPromise();
  }

  getMovements(params) {
    return this.httpClient.get(this.url, {params}).toPromise();
  }

  downloadMovements(params) {
    return this.httpClient.get(this.url + `/download`,
      { observe: 'response', responseType: 'arraybuffer', params})
      .toPromise()
      .then(response => {
        const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
        const blob = new Blob([response.body], {type: response.type.toString()});
        saveAs(blob, filename);
      });
  }

  downloadMovementFile(movementId) {
    return this.httpClient.get(this.url + `/${movementId}/file`,
      { observe: 'response', responseType: 'arraybuffer'})
      .toPromise()
      .then(response => {
        const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
        const blob = new Blob([response.body], {type: response.type.toString()});
        saveAs(blob, filename);
      });
  }
}
