import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Yacht} from '../models/reservation';

@Injectable({
  providedIn: 'root'
})
export class YachtService {
  private url = environment.apiUrl + 'yachts';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllYachts(): Promise<any> {
    return this.httpClient.get(this.url)
      .pipe(
        map((response: any) => {
          const yachts = [];
          for (const y of response['data']) {
            const disabled = [];
            if (y.is_active_admin === 0) {
              disabled.push('admin');
            }
            if (y.is_active_web === 0) {
              disabled.push('web');
            }
            if (y.is_active_partners === 0) {
              disabled.push('partners');
            }

            let str = '';
            if (disabled.length > 0) {
              str = '(Desact. para ' + disabled.join(', ') + ')';
            }
            yachts.push({
              id: y.id,
              name: y.name,
              is_active_web: y.is_active_web,
              is_active_admin: y.is_active_admin,
              is_active_partners: y.is_active_partners,
              active_str: str
            });
          }

          return yachts;
        })
      )
      .toPromise();
  }

  getYachtById(yachtId: any) {
    return this.httpClient.get(this.url + `/${yachtId}/full`)
      .toPromise();
  }

  updateYachtById(yachtId: any, body: any) {
    return this.httpClient.put(this.url + `/${yachtId}`, body)
      .toPromise();
  }

  switchYachtStatusById(yachtId: number, type: string) {
    return this.httpClient.patch(this.url + `/${yachtId}/${type}/switch`, null)
      .toPromise();
  }
}
