import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Status, Yacht} from '../../models/reservation';
import {YachtService} from '../../services/yacht.service';
import {ReservationsService} from '../../services/reservations.service';
import {ReservationStatusService} from '../../services/reservation-status.service';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styles: [
  ]
})
export class DownloadReportComponent implements OnInit {

  form: UntypedFormGroup;

  yachts: Yacht[] = [];
  status: Status[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private yachtService: YachtService,
    private reservationStatusService: ReservationStatusService,
    private reservationsService: ReservationsService,
  ) {
    this._buildForm();
  }

  ngOnInit(): void {

    const promises = [
      this.yachtService.getAllYachts(),
      this.reservationStatusService.getAllStatus(),
    ];

    Promise.all(promises).then(responses => {
      this.yachts = responses[0];
      this.status = responses[1]['data'];
    });
  }

  async onSubmitForm() {
    if (this.form.valid) {
      const params = {
        from: this.form.value.from.toISOString().split('T')[0],
        to: this.form.value.to.toISOString().split('T')[0],
      };

      if (this.form.value.yacht) {
        params['yacht'] = this.form.value.yacht;
      }

      if (this.form.value.status) {
        params['status'] = this.form.value.status;
      }
      await this.reservationsService.getReport(params);
    }
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
      from: [null, Validators.required],
      to: [null, Validators.required],
      yacht: [null],
      status: [null],
    });
  }

}
