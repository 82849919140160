import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Extra, PaymentMethod, PaymentType, Promocode, Source, Status, Yacht} from '../../models/reservation';
import {YachtService} from '../../services/yacht.service';
import {ReservationStatusService} from '../../services/reservation-status.service';
import {ReservationExtrasService} from '../../services/reservation-extras.service';
import {ReservationSourcesService} from '../../services/reservation-sources.service';
import {PaymentTypesService} from '../../services/payment-types.service';
import {ReservationsService} from '../../services/reservations.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PromocodesService} from '../../services/promocodes.service';
import {HourPricesService} from '../../services/hour-prices.service';
import HourPrice from '../../models/hour-price';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {PaymentMethodsService} from '../../services/payment-methods.service';

@Component({
  selector: 'app-new-reservation',
  templateUrl: './new-reservation.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class NewReservationComponent implements OnInit {
  @ViewChild('errorSwal') errorSwal: SwalComponent;
  @ViewChild('exceedsCheckinSwal') exceedsCheckinSwal: SwalComponent;

  form: UntypedFormGroup;
  extrasArr = new UntypedFormArray([]);
  yachts = [];
  status: Status[] = [];
  paymentStatus: Status[] = [];
  extras: Extra[] = [];
  sources: Source[] = [];
  paymentTypes: PaymentType[] = [];
  paymentMethods: PaymentMethod[] = [];
  promocodes: Promocode[] = [];
  prices: HourPrice[] = [];
  canEdit: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private yachtsService: YachtService,
    private reservationStatusService: ReservationStatusService,
    private extrasService: ReservationExtrasService,
    private sourcesService: ReservationSourcesService,
    private paymentTypesService: PaymentTypesService,
    private paymentMethodsService: PaymentMethodsService,
    private reservationsService: ReservationsService,
    private promocodesService: PromocodesService,
    private hourPricesService: HourPricesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
      if (!this.canEdit) {
        this.router.navigate(['/dashboard']);
      }
    }).unsubscribe();
    this._buildForm();
  }

  ngOnInit() {

    const promises = [
      this.yachtsService.getAllYachts(),
      this.reservationStatusService.getAllStatus(),
      this.extrasService.getAllExtras(),
      this.sourcesService.getAllSources(),
      this.paymentTypesService.getAllPaymentTypes(),
      this.paymentMethodsService.getAllPaymentMethods(),
      this.promocodesService.getAllPromocodes(),
      this.reservationStatusService.getAllPaymentStatus(),
    ];

    Promise.all(promises).then(resp => {
      this.yachts = resp[0];
      this.status = resp[1]['data'];
      this.extras = resp[2]['data'];
      this.sources = resp[3]['data'];
      this.paymentTypes = resp[4]['data'];
      this.paymentMethods = resp[5]['data'];
      this.promocodes = resp[6]['data'];
      this.paymentStatus = resp[7]['data'];
      this._setExtras();

    });
  }

  updateExtraPriceField(index: number) {
    const group = this.extrasArr.at(index) as UntypedFormGroup;
    const priceCtrl = group.get('price');

    if (group.get('selected').value) {
      priceCtrl.setValidators(Validators.required);
      if (priceCtrl.value === null) {
        priceCtrl.setValue(this.extras[index].price);
      }
      priceCtrl.enable();
    } else {
      priceCtrl.clearValidators();
      priceCtrl.disable();
    }

    this.updateExtrasPrice();
  }

  updatePrices() {
    this.hourPricesService.getHourPricesByYachtId(this.form.value.yacht).then(resp => {
      this.prices = resp['data'];
      this.form.get('hours').enable();
      if (this.form.get('hours').value !== null) {
        this.updateHoursPrice();
      }
    });
  }

  updateHoursPrice() {
    const hoursCtrl = this.form.get('hours');
    const hoursPriceCtrl = this.form.get('hoursPrice');
    const hoursPrice = this.prices.find((p) => {
      return p.hours === hoursCtrl.value;
    });

    hoursPriceCtrl.setValue(hoursPrice.price);
    hoursPriceCtrl.enable();
    this.updateReservationPrice();
  }

  updateDockFee() {
    const adultsFee = this.form.get('adults').value * 150;
    const childrenFee = this.form.get('children').value * 150;
    const dockFeeCtrl = this.form.get('dockFee');

    dockFeeCtrl.setValue(adultsFee + childrenFee);
    dockFeeCtrl.enable();
    this.updateReservationPrice();
  }

  updateExtraHoursPrice() {
    const pricePerExtraHourCtrl = this.form.get('pricePerExtraHour');
    const extraHoursCtrl = this.form.get('extraHours');
    const extraHoursPriceCtrl = this.form.get('extraHoursPrice');

    extraHoursPriceCtrl.setValue(pricePerExtraHourCtrl.value * extraHoursCtrl.value);
    this.updateReservationPrice();
  }

  updateReservationPrice() {
    const dockFeeCtrl = this.form.get('dockFee');
    const reservFeeCtrl = this.form.get('reservationFee');
    const hoursPriceCtrl = this.form.get('hoursPrice');
    const extraHoursPriceCtrl = this.form.get('extraHoursPrice');
    const reservPriceCtrl = this.form.get('reservationPrice');

    reservPriceCtrl.setValue(dockFeeCtrl.value + reservFeeCtrl.value + hoursPriceCtrl.value + extraHoursPriceCtrl.value);
    this.updatePromocodeDiscount();
  }

  updatePromocodeDiscount() {
    const codeCtrl = this.form.get('promocode');
    const discountCtrl = this.form.get('promocodeDiscount');

    if (codeCtrl.value === null) {
      discountCtrl.setValue(0);
    } else {
      const code = this.promocodes.find((c) => c.id === codeCtrl.value);
      const hoursPrice = this.form.get('hoursPrice').value;
      let discount = 0;

      if (code.type.id === 1) {
        discount = hoursPrice * (code.discount / 100);
      } else if (code.type.id === 2) {
        discount = code.discount;
      }

      discountCtrl.setValue(discount);
    }

    this.updateTotalPrice();
  }

  updateTotalPrice() {
    const reservPriceCtrl = this.form.get('reservationPrice');
    const discountCtrl = this.form.get('promocodeDiscount');
    const totalPriceCtrl = this.form.get('totalPrice');

    totalPriceCtrl.setValue(reservPriceCtrl.value - discountCtrl.value);
    this.updateFinalPrice();
  }

  updateExtrasPrice() {
    const price = this.extrasArr.getRawValue()
      .filter(e => e.selected)
      .reduce((acc, e) => {
        return acc + e.price;
      }, 0);

    this.form.get('extrasPrice').setValue(price);
    this.updateFinalPrice();
  }

  updateFinalPrice() {
    const salesFeeCtrl = this.form.get('salesFee');
    const totalPriceCtrl = this.form.get('totalPrice');
    const extrasPriceCtrl = this.form.get('extrasPrice');
    const finalPriceCtrl = this.form.get('finalPrice');

    finalPriceCtrl.setValue(totalPriceCtrl.value + extrasPriceCtrl.value + salesFeeCtrl.value);
  }

  onSelectMethod() {
    if (this.form.value.paymentMethod === 2) {
      this.form.get('exchange').enable();
    }
  }

  submitForm() {
    if (this.form.valid) {

      // if (!this._isValidCheckin()) { return; }

      const extras = this.extrasArr.getRawValue().filter(e => e.selected);
      const body: any = {
        client: {
          name: this.form.value.name,
          lastName: this.form.value.lastName,
          phone: this.form.value.phone,
          email: this.form.value.email,
        },
        reservation: {
          yacht: this.form.value.yacht,
          date: this.form.value.date.toISOString().split('T')[0],
          hours: this.form.value.hours,
          checkin: this.form.value.checkin,
          adults: this.form.value.adults,
          children: this.form.value.children,
          status: this.form.value.status,
          paymentStatus: this.form.value.paymentStatus,
          extras,
          dockFee: this.form.value.dockFee,
          reservationFee: this.form.value.reservationFee,
          hoursPrice: this.form.value.hoursPrice,
          reservationPrice: this.form.value.reservationPrice,
          pricePerExtraHour: this.form.value.pricePerExtraHour,
          extraHours: this.form.value.extraHours,
          extraHoursPrice: this.form.value.extraHoursPrice,
          promocode: this.form.value.promocode,
          promocodeDiscount: this.form.value.promocodeDiscount,
          totalPrice: this.form.value.totalPrice,
          extrasPrice: this.form.value.extrasPrice,
          salesFee: this.form.value.salesFee,
          finalPrice: this.form.value.finalPrice,
          source: this.form.value.source,
          reference: this.form.value.reference,
        }
      };

      if (this._isValidPayment()) {
        body.payment = {
          amount: this.form.value.amount,
          reference: this.form.value.paymentReference,
          type: this.form.value.paymentType,
          method: this.form.value.paymentMethod,
          exchange: this.form.value.exchange,
          date: this.form.value.paymentDate
        };
      }

      this.reservationsService.registerReservation(body).then(resp => {
        this.router.navigate(['/reservaciones', resp['data']]);
      }).catch((err) => {
        this.errorSwal.fire();
      });
    }
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      yacht: [null, Validators.required],
      date: [null, Validators.required],
      hours: [{value: null, disabled: true}, Validators.required],
      checkin: [null, Validators.required],
      adults: [0, [Validators.required, Validators.min(0)]],
      children: [0, [Validators.required, Validators.min(0)]],
      status: [null, Validators.required],
      paymentStatus: [null, Validators.required],
      extras: new UntypedFormArray([]),
      dockFee: [{value: 0, disabled: true}, [Validators.min(0)]],
      reservationFee: [100, [Validators.required, Validators.min(0)]],
      hoursPrice: [{value: null, disabled: true}, [Validators.required, Validators.min(0)]],
      pricePerExtraHour: [1500, [Validators.required, Validators.min(0)]],
      extraHours: [0, [Validators.required, Validators.min(0)]],
      extraHoursPrice: [0, [Validators.required, Validators.min(0)]],
      reservationPrice: [null, [Validators.required, Validators.min(0)]],
      promocode: [null],
      promocodeDiscount: [0, [Validators.required, Validators.min(0)]],
      totalPrice: [null, [Validators.required, Validators.min(0)]],
      extrasPrice: [0, [Validators.required, Validators.min(0)]],
      salesFee: [0, [Validators.required, Validators.min(0)]],
      finalPrice: [null, Validators.required],
      source: [null, Validators.required],
      reference: [null, Validators.required],
      amount: [null],
      paymentType: [null],
      paymentMethod: [null],
      exchange: [{value: null, disabled: true}],
      paymentDate: [null],
      paymentReference: [null],
    });

    this.extrasArr = this.form.get('extras') as UntypedFormArray;
    this.updateReservationPrice();
  }

  private _setExtras() {
    this.extrasArr.clear();
    for (const extra of this.extras) {
      this.extrasArr.push(this.formBuilder.group({
        id: [extra.id],
        selected: [false],
        price: [{value: extra.price, disabled: true}],
      }));
    }
  }

  private _isValidCheckin() {
    const checkin = Number(this.form.value.checkin.substring(0, 2));
    const hours = this.form.value.hours;

    if (checkin + hours > 22) {
      this.exceedsCheckinSwal.fire();
      return false;
    }

    return true;
  }

  private _isValidPayment() {
    const amount = this.form.get('amount');
    const type = this.form.get('paymentType');
    const date = this.form.get('paymentDate');

    return amount.valid && amount.value !== null &&
      type.valid && type.value !== null &&
      date.valid && date.value !== null;
  }

}
