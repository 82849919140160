import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MembershipsService {
  private url = environment.apiUrl + 'memberships';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllMembreships() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  getMembershipById(membershipId: string) {
    return this.httpClient.get(this.url + `/${membershipId}`)
      .toPromise();
  }

  updateMembershipById(membershipId: string, body: any) {
    return this.httpClient.put(this.url + `/${membershipId}`, body)
      .toPromise();
  }

  updateMemberByMembershipId(membershipId: string, body: any) {
    return this.httpClient.put(this.url + `/${membershipId}/member`, body)
      .toPromise();
  }

  updateSourceByMembershipId(membershipId: string, body: any) {
    return this.httpClient.put(this.url + `/${membershipId}/source`, body)
      .toPromise();
  }
}
