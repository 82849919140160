import {LOCALE_ID, Inject, Injectable} from '@angular/core';
import {CalendarEventTitleFormatter, CalendarEvent} from 'angular-calendar';
import {formatDate, formatCurrency} from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  month(event: CalendarEvent): string {
    let source = event.meta.source;
    if (typeof source === 'undefined') {
      source = 'S/D';
    }
    const reference = event.meta.reference ?? 'S/D';
    return `<b>${formatDate(event.start, 'shortTime', this.locale)}</b>, ${
      event.title
    }: ${
      formatCurrency(event.meta.price, this.locale, '$', null, '1.2-2')
    }, ${source}, ${reference}, ${event.meta.hours} horas`;
  }

  week(event: CalendarEvent): string {
    let source = event.meta.source;
    if (typeof source === 'undefined') {
      source = 'S/D';
    }
    const reference = event.meta.reference ?? 'S/D';
    return `<b>${formatDate(event.start, 'shortTime', this.locale)}</b>, ${
      event.title
    }: ${
      formatCurrency(event.meta.price, this.locale, '$', null, '1.2-2')
    }, ${source}, ${reference}, ${event.meta.hours} horas`;
  }

  day(event: CalendarEvent): string {
    let source = event.meta.source;
    if (typeof source === 'undefined') {
      source = 'S/D';
    }
    const reference = event.meta.reference ?? 'S/D';
    return `<b>${formatDate(event.start, 'shortTime', this.locale)}</b>, ${
      event.title
    }: ${
      formatCurrency(event.meta.price, this.locale, '$', null, '1.2-2')
    }, ${source}, ${reference}, ${event.meta.hours} horas`;
  }
}
