<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">Yates</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">
              <i class="feather icon-home"></i>
            </a>
          </li>
          <li class="breadcrumb-item">
            <a>
              Yates
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div *ngIf="yachts.length === 0" class="alert alert-warning">
    <h6>
      No hay yates registrados
    </h6>
  </div>
  <div *ngFor="let y of yachts" class="col-sm-4">
    <div class="card">
      <div class="card-body text-center">
          <h5 class="mt-1">{{y.name}}</h5>
        <hr>
        <div class="row">
          <div class="col-sm-12 d-inline-flex">
            <div class="custom-control custom-switch mr-4">
              <input type="checkbox"
                     [disabled]="!canEdit"
                     [checked]="y.is_active_admin"
                     (change)="switchStatus(y.id, 'admin')"
                     class="custom-control-input" id="admin{{y.id}}">
              <label class="custom-control-label" for="admin{{y.id}}">Admin</label>
            </div>
            <div class="custom-control custom-switch mr-4">
              <input type="checkbox"
                     [disabled]="!canEdit"
                     [checked]="y.is_active_web"
                     (change)="switchStatus(y.id, 'web')"
                     class="custom-control-input" id="web{{y.id}}">
              <label class="custom-control-label" for="web{{y.id}}">Web</label>
            </div>
            <div class="custom-control custom-switch mr-4">
              <input type="checkbox"
                     [disabled]="!canEdit"
                     [checked]="y.is_active_partners"
                     (change)="switchStatus(y.id, 'partners')"
                     class="custom-control-input" id="partners{{y.id}}">
              <label class="custom-control-label" for="partners{{y.id}}">Partners</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button (click)="onSelectYacht(y.id)" class="btn btn-block btn-primary mt-3">
              GESTIONAR PRECIOS
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

