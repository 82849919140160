<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">MEMBRESÍAS ANUALES</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="feather icon-home"></i></a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">CONTROL DE MIEMBROS</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <!-- YM - MEMBER CARD -->
  <div *ngFor="let m of memberships" class="col-lg-4 col-md-6">
    <div class="card user-card user-card-1 mt-4">
      <div class="card-body pt-0">
        <div class="user-about-block text-center">
          <div class="row align-items-end">
            <div class="col text-left pb-3">
              <span *ngIf="m.status.id === 1" class="badge badge-warning">{{m.status.name}}</span>
              <span *ngIf="m.status.id === 2" class="badge badge-success">{{m.status.name}}</span>
              <span *ngIf="m.status.id === 3" class="badge badge-danger">{{m.status.name}}</span>
            </div>
            <div class="col"><img class="img-radius img-fluid wid-80" src="https://demo-home.yachtmastersclub.com/_assets/img/logo/yachtmstr_dark.png"></div>
            <div class="col text-right pb-3">
              <div class="dropdown">
                <a class="drp-icon dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-more-horizontal"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item mb-1" [routerLink]="[m.id]">Detalles</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a>
            <h4 class="mb-1 mt-3">{{m.member.first_name}} {{m.member.last_name}}</h4>
          </a>
          <p class="mb-3 text-muted">
            <i class="feather icon-calendar"> </i>
            {{m.activation_date ? (m.activation_date|date:'dd/MM/y') : 'N/A'}} -
            {{m.expiration_date ? (m.expiration_date|date:'dd/MM/y') : 'N/A'}}</p>
          <p class="mb-0">
            <span *ngIf="m.plan.id === 1" class="badge badge-warning">{{m.plan.name}}</span>
            <span *ngIf="m.plan.id === 2" class="badge badge-secondary">{{m.plan.name}}</span>
            <span *ngIf="m.plan.id === 3" class="badge badge-dark">{{m.plan.name}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
