<div class="auth-wrapper">
  <div class="auth-content">
    <div class="card">
      <div class="row align-items-center text-center">
        <div class="col-md-12">
          <div class="card-body">
            <img src="https://demo-home.yachtmastersclub.com/_assets/img/logo/yachtmstr_dark.png" alt="" class="img-fluid mb-4">
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>Usuario</mat-label>
                  <input matInput formControlName="username">
                  <mat-error *ngIf="form.controls.username.hasError('required')">Campo requerido</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group mb-4">
                <mat-form-field>
                  <mat-label>Contraseña</mat-label>
                  <input matInput type="password" formControlName="password">
                  <mat-error *ngIf="form.controls.password.hasError('required')">Campo requerido</mat-error>
                </mat-form-field>
              </div>
              <div class="alert alert-danger" *ngIf="forbidden">
                <i class="fa fa-times"></i>
                Usuario y/o contraseña incorrectos
              </div>
              <div class="alert alert-danger" *ngIf="serverError">
                <i class="fa fa-times"></i>
                Error en el servidor. Intente más tarde
              </div>
              <button type="submit" class="btn btn-block btn-primary mb-4">CONTINUAR</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
