import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../services/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EditUserRolesComponent} from '../edit-user-roles/edit-user-roles.component';
import {UserComponent} from '../user/user.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: [
  ]
})
export class UsersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['id', 'name', 'user', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('editPermDlg') editPermDlg: TemplateRef<any>;
  detailDialogRef: MatDialogRef<any> = null;

  users = [];
  canEdit: boolean;

  form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
  }

  ngOnInit(): void {
    this.usersService.getAllUsers().then(response => {
      this.users = response['data'];
      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  editPermissions(user) {
    const canEdit = this.canEdit;
    const dialogRef = this.dialog.open(EditUserRolesComponent, {
      data: {user, canEdit}
    });
  }

  onUpdatePermissions() {
    if (this.form.valid) {
      const body = {
        roleId: this.form.value.role
      };
      this.usersService.updateUserById(this.form.value.id, body).then(() => {
        this.detailDialogRef.close();
        this.usersService.getAllUsers().then(response => {
          this.users = response['data'];
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    }
  }

  createUser() {
    const dialogRef = this.dialog.open(UserComponent, {
      data: {},
    });

    dialogRef.beforeClosed().subscribe(() => {
      this.usersService.getAllUsers().then(response => {
        this.users = response['data'];
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  onDeleteUser(userId: any) {
    Swal.fire({
      titleText: 'Eliminar usuario',
      icon: 'warning',
      text: '¿Desea eliminar el usuario?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: 'red',
    }).then(result => {
      if (result.isConfirmed) {
        this.usersService.deleteUserById(userId).then(() => {
          this.usersService.getAllUsers().then(response => {
            this.users = response['data'];
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      }
    });
  }
}
