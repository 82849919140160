<!--<div class="loader-bg">-->
<!--  <div class="loader-track">-->
<!--    <div class="loader-fill"></div>-->
<!--  </div>-->
<!--</div>-->
<header class="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
  <div class="m-header">
    <a class="mobile-menu" id="mobile-collapse"><span></span></a>
    <a href="https://demo-home.yachtmastersclub.com" class="b-brand">
      <!-- YM - LOGO -->
      <img src="https://demo-home.yachtmastersclub.com/_assets/img/logo/yachtmstr_dark.png" width="148px" class="logo">
    </a>
    <a href="#" class="mob-toggler">
      <i class="feather icon-more-vertical"></i>
    </a>
  </div>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li>
        <div class="dropdown drp-user">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="feather icon-user"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right profile-notification">
            <div class="pro-head">
              <i class="fa fa-user fa-2x mr-2"></i>
              <span>{{userInfo.name}}</span>
              <a href="" (click)="$event.preventDefault(); logoutSwal.fire()" class="dud-logout">
                <i class="feather icon-log-out"></i>
              </a>
            </div>
            <ul class="pro-body">
              <li>
                <a href="https://demo-home.yachtmastersclub.com/" target="_blank" class="dropdown-item">
                  <i class="feather icon-home"></i>VER SITIO
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</header>

<nav class="pcoded-navbar menu-light" style="z-index: 99;">
  <div class="navbar-wrapper">
    <div class="navbar-content scroll-div">
      <div class="">
        <div class="main-menu-header">
          <img class="img-radius" src="https://demo-home.yachtmastersclub.com/_assets/img/logo/yachtmstr_dark.png" alt="User-Profile-Image">
          <div class="user-details">
            <div id="more-details">{{userInfo.name}}</div>
          </div>
        </div>
      </div>

      <ul class="nav pcoded-inner-navbar">
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Dashboard</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/dashboard']">Estadísticas</a>
            </li>
            <li>
              <a [routerLink]="['/yates']">Yates</a>
            </li>
            <li>
              <a [routerLink]="['/partners']">Partners</a>
            </li>
          </ul>
        </li>
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Reservaciones</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/reservaciones']">Controlador</a>
            </li>
            <li>
              <a [routerLink]="['/reservaciones/nueva']">Registro</a>
            </li>
            <li>
              <a [routerLink]="['/reservaciones/reporte']">Reporte</a>
            </li>
            <li>
              <a [routerLink]="['/seguros']">Registro seguros</a>
            </li>
            <li>
              <a [routerLink]="['/newsletter']">Newsletter</a>
            </li>
          </ul>
        </li>
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Operaciones</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/gastos-generales']">Gastos generales</a>
            </li>
            <li>
              <a [routerLink]="['/combustibles']">Combustibles</a>
            </li>
          </ul>
        </li>
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Usuarios</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/usuarios']">Perfiles</a>
            </li>
          </ul>
        </li>
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Membresías</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/membresias/anuales']">Anuales</a>
            </li>
            <li>
              <a [routerLink]="['/membresias/sin-fronteras']">Sin Fronteras</a>
            </li>
          </ul>
        </li>
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Calendario</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/calendario']">Itinerarios</a>
            </li>
          </ul>
        </li>
        <li class="nav-item pcoded-hasmenu active pcoded-trigger">
          <a class="nav-link">
            <span class="pcoded-micon">
              <i class="feather icon-anchor"></i>
            </span>
            <span class="pcoded-mtext">Promociones</span>
          </a>
          <ul class="pcoded-submenu">
            <li>
              <a [routerLink]="['/cupones']">Lista de cupones</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="pcoded-main-container">
  <div class="pcoded-content">
    <router-outlet></router-outlet>
  </div>
</div>

<swal #logoutSwal
      titleText="¿Cerrar sesión?"
      icon="question"
      confirmButtonText="Sí"
      cancelButtonText="Cancelar"
      showCancelButton="true"
      focusCancel="true"
      (confirm)="logout()"
></swal>
