<h1 mat-dialog-title>Editando {{yacht.name}}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table table-hover table-responsive">
          <thead>
          <tr>
            <td>Horas</td>
            <td>Admin</td>
            <td>Web</td>
            <td>Web (USD)</td>
          </tr>
          </thead>
          <tbody>
          <ng-container formArrayName="prices">
            <tr *ngFor="let _ of pricesArr.controls; index as i">
              <ng-container [formGroupName]="i.toString()">
                <td>
                  <h6>{{yacht.prices[i].hours}}</h6>
                </td>
                <td>
                  <mat-form-field>
                    <input matInput formControlName="price" type="number" min="0">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field>
                    <input matInput formControlName="online_price" type="number" min="0">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field>
                    <input matInput formControlName="usd_online_price" type="number" min="0">
                  </mat-form-field>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
  <button class="btn btn-info float-right" mat-button (click)="onSubmit()" [disabled]="form.invalid || !canEdit">Guardar</button>
</mat-dialog-actions>
