<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">CALENDARIO DE RESERVACIONES</h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <div class="row text-center mb-4">
      <div class="col-md-4">
        <div class="btn-group float-left">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >
            Anterior
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
          >
            Hoy
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >
            Siguiente
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group float-right">
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            Mes
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            Semana
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
          >
            Día
          </div>
        </div>
      </div>
    </div>
    <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent($event.event)"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh"
        (eventClicked)="handleEvent($event.event)"
        [dayStartHour]="7"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [refresh]="refresh"
        (eventClicked)="handleEvent($event.event)"
        [dayStartHour]="7"
      >
      </mwl-calendar-day-view>
    </div>
    <div class="mt-3">
      <span class="mr-3">
        <span class="fa fa-circle" style="color: #ad2121"></span>
        <span class="text-secondary ml-1">TRIANA</span>
      </span>
      <span class="mr-3">
        <span class="fa fa-circle" style="color: #1e90ff"></span>
        <span class="text-secondary ml-1">MANATEE</span>
      </span>
      <span class="mr-3">
        <span class="fa fa-circle" style="color: #e3bc08"></span>
        <span class="text-secondary ml-1">GYPSEA</span>
      </span>
      <span class="mr-3">
        <span class="fa fa-circle" style="color: #058a00"></span>
        <span class="text-secondary ml-1">FLINTSTONE</span>
      </span>
      <span class="mr-3">
        <span class="fa fa-circle" style="color: #9933ff"></span>
        <span class="text-secondary ml-1">APLOMADO</span>
      </span>
    </div>
  </div>
</div>
<swal titleText="Detalle de reservación"
      (confirm)="viewReservationDetail()"
      confirmButtonText="Ver detalle"
      [showCancelButton]="true"
      cancelButtonText="Regresar"
      [focusCancel]="true"
      #detailSwal>
  <div *swalPortal="swalTargets.content" class="text-left">
    <table class="table">
      <tbody>
      <tr>
        <th scope="row">Precio</th>
        <td>$ {{selectedEvent.meta.price|number:'1.2-2'}}</td>
      </tr>
      <tr>
        <th scope="row">Referencia</th>
        <td>{{selectedEvent.meta.reference}}</td>
      </tr>
      <tr>
        <th scope="row">Origen</th>
        <td>{{selectedEvent.meta.source}}</td>
      </tr>
      <tr>
        <th scope="row">Cliente</th>
        <td>{{selectedEvent.meta.client}}</td>
      </tr>
      <tr>
        <th scope="row">Yate</th>
        <td>{{selectedEvent.meta.yacht}}</td>
      </tr>
      <tr>
        <th scope="row">Fecha y hora</th>
        <td>{{selectedEvent.start|date:'dd/MM/y HH:mm:ss'}}</td>
      </tr>
      <tr>
        <th scope="row">Horas</th>
        <td>{{selectedEvent.meta.hours}}</td>
      </tr>
      <tr>
        <th scope="row">Adultos</th>
        <td>{{selectedEvent.meta.adults}}</td>
      </tr>
      <tr>
        <th scope="row">Niños</th>
        <td>{{selectedEvent.meta.children}}</td>
      </tr>
      <tr>
        <th scope="row">Status</th>
        <td>{{selectedEvent.meta.status}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</swal>
