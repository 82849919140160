import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {
  private url = environment.apiUrl + 'partners';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllPartners() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  updatePartner(partnerId: number, body: any) {
    return this.httpClient.put(this.url + `/${partnerId}`, body)
      .toPromise();
  }
}
