import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private url = environment.apiUrl + 'users';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllUsers() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  createUser(body: any) {
    return this.httpClient.post(this.url, body)
      .toPromise();
  }

  updateUserById(userId: any, body: any) {
    return this.httpClient.put(this.url + `/${userId}`, body)
      .toPromise();
  }

  deleteUserById(userId: any) {
    return this.httpClient.delete(this.url + `/${userId}`)
      .toPromise();
  }

  checkRoll(moduleId: any) {
    return this.httpClient.post(this.url + `/check-role`, {moduleId})
      .toPromise();
  }

  getUserRoles(userId: any) {
    return this.httpClient.get(this.url + `/roles/${userId}`)
      .toPromise();
  }

  updateUserRoles(userId: any, body: object) {
    return this.httpClient.put(this.url + `/roles/${userId}`, body)
      .toPromise();
  }
}
