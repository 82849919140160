import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NoLimitsMembershipsService {
  private url = environment.apiUrl + 'no-limits';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllMembreships() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  getMembershipById(membershipId: string) {
    return this.httpClient.get(this.url + `/${membershipId}`)
      .toPromise();
  }

  updateMembershipById(membershipId: string, body: any) {
    return this.httpClient.put(this.url + `/${membershipId}`, body)
      .toPromise();
  }

  updateSourceById(membershipId: string, body: any) {
    return this.httpClient.put(this.url + `/${membershipId}/source`, body)
      .toPromise();
  }

  createComment(membershipId: string, body: any) {
    return this.httpClient.post(environment.apiUrl + `no-limits-comments/${membershipId}`, body)
      .toPromise();
  }

  createPayment(membershipId: string, body: any) {
    return this.httpClient.post(environment.apiUrl + `no-limits-payments/${membershipId}`, body)
      .toPromise();
  }
}
