import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import Partner from '../../models/partner';
import {PartnersService} from '../../services/partners.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styles: [
  ]
})
export class PartnersComponent implements OnInit  {

  @ViewChild('viewCodeDialog') viewCodeDialog: TemplateRef<any>;

  dialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
  };
  detailDialogRef: MatDialogRef<any> = null;

  partners: Partner[] = [];

  selectedPartner: Partner = null;
  selectedPartnerForm: UntypedFormGroup;
  canEdit: boolean;

  constructor(
    private partnersService: PartnersService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this._buildForms();
  }

  ngOnInit(): void {
    const promises = [
      this.partnersService.getAllPartners(),
    ];

    Promise.all(promises).then(resp => {
      this.partners = resp[0]['data'];

    });
  }

  async updatePartner() {
    if (this.selectedPartnerForm.valid) {

      await this.partnersService.updatePartner(this.selectedPartner.id, this.selectedPartnerForm.value);
      await this._refreshPartners();
      this.detailDialogRef.close();
      this.selectedPartnerForm.markAsPristine();
    }
  }

  viewDetail(partner: Partner) {
    this._setFormData(partner);
    this._openViewDetailDialog();
  }

  private _buildForms() {
    this.selectedPartnerForm = this.formBuilder.group({
      name: [null, Validators.required],
      username: [null, Validators.required],
      pricePerHour: [0, Validators.required],
      dockFee: [5, Validators.required],
    });

    if (!this.canEdit) {
      this.selectedPartnerForm.disable();
    }
  }

  private _refreshPartners() {
    return this.partnersService.getAllPartners().then(resp => {
      this.partners = resp['data'];
    });
  }

  private _openViewDetailDialog() {
    this.detailDialogRef = this.dialog.open(this.viewCodeDialog, this.dialogConfig);
  }

  private _setFormData(partner: Partner) {
    this.selectedPartnerForm.setValue({
      name: partner.name,
      username: partner.username,
      pricePerHour: partner.price_per_hour,
      dockFee: partner.usd_dock_fee,
    });

    this.selectedPartner = partner;
  }

}
