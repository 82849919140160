import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromocodesService {
  private url = environment.apiUrl + 'promocodes';
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllPromocodes() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  createPromocode(body: any) {
    return this.httpClient.post(this.url, body)
      .toPromise();
  }

  updatePromocode(promocodeId: number, body: any) {
    return this.httpClient.put(this.url + `/${promocodeId}`, body)
      .toPromise();
  }

  switchPromocodeStatus(promocodeId: number) {
    return this.httpClient.put(this.url + `/${promocodeId}/switch`, null)
      .toPromise();
  }

  deletePromocode(promocodeId: number) {
    return this.httpClient.delete(this.url + `/${promocodeId}`)
      .toPromise();
  }
}
