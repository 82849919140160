import {MembershipComment, MembershipPayment, MembershipStatus} from './membership';
import {Promocode, Source} from './reservation';

export class NoLimitsMembership {
  public id?: number;
  public first_name?: string;
  public last_name?: string;
  public phone?: string;
  public email?: string;
  public status_id?: number;
  public activation_date?: any;
  public expiration_date?: any;
  public status?: MembershipStatus;
  public source?: Source;
  public source_id: number;
  public reference: string;
  public payments?: MembershipPayment[];
  public comments?: MembershipComment[];
  public promocode?: Promocode;
}
