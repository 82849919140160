<!-- YM - VIEW HEADER -->
<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">Dashboard de Reservas</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="https://demo-home.yachtmastersclub.com"><i class="feather icon-home"></i></a></li>
          <li class="breadcrumb-item"><a href="#">dashboard</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <!--<iframe src="https://datastudio.google.com/embed/reporting/6b306960-4a79-44a1-b481-eb2fc41b105b/page/CtTZC"
                frameborder="0" style="border:0; width: 100%; height: 900px;" allowfullscreen></iframe>-->
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
