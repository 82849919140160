import {Component, ChangeDetectionStrategy, OnInit, ViewChild} from '@angular/core';
import {isSameDay, isSameMonth} from 'date-fns';
import {CalendarDateFormatter, CalendarEvent, CalendarEventTitleFormatter, CalendarView} from 'angular-calendar';
import {CustomDateFormatter} from './date-formatter';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ReservationsService} from '../../services/reservations.service';
import {colors} from './colors';
import {CustomEventTitleFormatter} from './title-formatter';
import {SwalComponent, SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';

interface CalReservation {
  id: number;
  yacht: {
    name: string
  };
  yacht_id: number;
  hours: number;
  adults: number;
  children: number;
  client: string;
  status: {
    name: string
  };
  start: string;
  end: string;
  reference?: string;
  source?: {name: string};
  final_price: number;
}

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})
export class CalendarComponent implements OnInit {

  @ViewChild('detailSwal') public readonly swal!: SwalComponent;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  locale = 'es-MX';

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  selectedEvent: CalendarEvent = null;

  activeDayIsOpen = false;

  constructor(
    private router: Router,
    private reservationsService: ReservationsService,
    public readonly swalTargets: SwalPortalTargets
  ) {
  }

  ngOnInit() {
    this.reservationsService.getReservationsForCalendar().then(resp => {
      const reservations = resp['data'] as Array<CalReservation>;

      for (const reservation of reservations) {
        const start = reservation.start.split(' ');
        const startDate = start[0].split('-');
        const startTime = start[1].split(':');

        const end = reservation.end.split(' ');
        const endDate = end[0].split('-');
        const endTime = end[1].split(':');

        this.events.push({
          id: reservation.id,
          title: reservation.yacht.name,
          meta: {
            client: reservation.client,
            yacht: reservation.yacht.name,
            hours: reservation.hours,
            adults: reservation.adults,
            children: reservation.children,
            status: reservation.status.name,
            reference: reservation.reference,
            source: reservation.source?.name,
            price: reservation.final_price,
          },
          start: new Date(
            Number(startDate[0]),
            Number(startDate[1]) - 1,
            Number(startDate[2]),
            Number(startTime[0]),
            Number(startTime[1]),
          ),
          end: new Date(
            Number(endDate[0]),
            Number(endDate[1]) - 1,
            Number(endDate[2]),
            Number(endTime[0]),
            Number(endTime[1]),
          ),
          color: this._getColorByYatch(reservation.yacht_id)
        });
      }
      this.refresh.next();
    });
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen =
        !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0);
      this.viewDate = date;
    }
  }

  handleEvent(event: CalendarEvent): void {
    this.selectedEvent = event;
    this.swal.fire();
  }

  viewReservationDetail() {
    this.router.navigate(['/reservaciones', this.selectedEvent.id]);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(date: Date) {
    this.activeDayIsOpen = false;
  }

  private _getColorByYatch(yachtId: any) {
    switch (yachtId) {
      case 1:
        return colors.red;
      case 2:
        return colors.blue;
      case 3:
        return colors.yellow;
      case 4:
        return colors.green;
      case 5:
        return colors.purple;
      default:
        return colors.red;
    }
  }
}
