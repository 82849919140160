import {AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component} from '@angular/core';
import {LoaderService} from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterContentChecked, AfterContentInit {
  title = 'dashboard';
  loading = false;

  constructor(
    private loaderService: LoaderService,
    private detectorRef: ChangeDetectorRef
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngAfterContentChecked() {
      this.detectorRef.detectChanges();
  }
  ngAfterContentInit() {
    this.detectorRef.detectChanges();
  }
}
