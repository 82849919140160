import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../../services/users.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styles: [
  ]
})
export class UserComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserComponent>,
    public usersService: UsersService
  ) {
    this._buildForm();
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.form.valid) {
      this.usersService.createUser(this.form.value).then(() => {
        this.dialogRef.close();
      }).catch(() => {
        this.dialogRef.close();
      });
    }
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.email],
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

}
