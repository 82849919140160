<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="page-header-title">
          <h5 class="m-b-10">DETALLES DE MEMBRESÍA</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="https://demo-home.yachtmastersclub.com"><i class="feather icon-home"></i></a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0);">MEMBRESÍA: {{membership.id}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- //YM - VIEW HEADER -->
<!-- YM - DASHBOARD -->
<div class="row">
  <div class="col-lg-8">
    <!-- YM - CLIENT INFO -->
    <form [formGroup]="membershipForm" (ngSubmit)="submitMembershipForm()">
      <div class="card">
        <div class="card-header">
          <h5>Datos del Titular</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Nombre</mat-label>
                  <input matInput formControlName="firstName" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input matInput formControlName="lastName" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>WhatsApp</mat-label>
                  <input matInput formControlName="phone" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Correo</mat-label>
                  <input matInput formControlName="email" type="email" required>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //YM - CLIENT INFO -->
      <!-- YM - RESERVATION INFO -->
      <div class="card">
        <div class="card-header">
          <h5>Datos de Membresía</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field (click)="actDatePicker.open()">
                  <mat-label>Fecha Activación</mat-label>
                  <input matInput [matDatepicker]="actDatePicker" formControlName="activationDate">
                  <mat-datepicker-toggle matSuffix [for]="actDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #actDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field (click)="expDatePicker.open()">
                  <mat-label>Fecha Vigencia</mat-label>
                  <input matInput [matDatepicker]="expDatePicker" formControlName="expirationDate">
                  <mat-datepicker-toggle matSuffix [for]="expDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #expDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of status" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit" class="btn btn-block btn-primary">ACTUALIZAR DATOS</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- //YM - RESERVATION INFO -->
    <!-- YM - PAYMENT APPLY -->
    <div class="card">
      <div class="card-header">
        <h5>Registro de Pagos</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="paymentForm" (ngSubmit)="submitPaymentForm()">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Monto de Pago</mat-label>
                  <input matInput formControlName="amount" type="number">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Forma de Pago</mat-label>
                  <mat-select formControlName="type">
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let t of paymentTypes" [value]="t.id">{{t.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field (click)="paymentDatePicker.open()">
                  <mat-label>Fecha de Pago</mat-label>
                  <input matInput [matDatepicker]="paymentDatePicker" formControlName="date">
                  <mat-datepicker-toggle matSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #paymentDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit" class="btn btn-block btn-primary">APLICAR PAGO</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- //YM - PAYMENT APPLY -->
    <!-- YM - PAYMENT LOGS -->
    <div class="card">
      <div class="card-header">
        <h5>Historial de Pagos</h5>
      </div>
      <div class="card-body">
        <div *ngIf="membership.payments?.length === 0" class="alert alert-secondary">
          No se han registrado pagos
        </div>
        <div *ngIf="membership.payments?.length > 0" class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>NO.</th>
              <th>MONTO</th>
              <th>TIPO</th>
              <th>FECHA</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of membership.payments; index as i;">
              <td>{{i + 1}}</td>
              <td>${{p.amount|number:'1.2-2'}}</td>
              <td>{{p.method?.name}}</td>
              <td>{{p.paid_at|date:'dd/MM/y'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- //YM - PAYMENT LOGS -->
    <!-- YM - RESERVATION SOURCE -->
    <div class="card">
      <div class="card-header">
        <h5>Origen de Venta</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="sourceForm" (ngSubmit)="submitSourceForm()">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Fuente</mat-label>
                  <mat-select formControlName="source" required>
                    <mat-option [value]="null" disabled>Selecciona una opción...</mat-option>
                    <mat-option *ngFor="let s of sources" [value]="s.id">{{s.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>Referencia</mat-label>
                  <input matInput formControlName="reference" required>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <button type="submit" class="btn btn-block btn-primary">ACTUALIZAR DATOS</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- //YM - RESERVATION SOURCE -->
  </div>
  <div class="col-lg-4">
    <div class="card hdd-right-inner">
      <div class="card-header">
        <h5>Resumen de Membresía</h5>
      </div>
      <!--<div class="card-body">
        <a
          href="https://api.whatsapp.com/send/?phone=522223456798&text=Hola Dann, tu reserva en Yacht Masters se ha actualizado. Puedes consultar todos los cambios en este link: http://yachtmastersclub.com/invoice.html"
          class="alert alert-success d-block text-center text-uppercase" target="_blank"><i
          class="feather icon-check-circle mr-2"></i>ENVIAR ACCESOS</a>
      </div>-->
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Código Personal</label>
            <div class="media-body">
              <p class="mb-0"><i class="feather icon-edit mr-1"></i>{{membership.promocode?.code}}
              </p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Activación</label>
            <div class="media-body">
              <p class="mb-0"><i class="feather icon-calendar mr-1"></i>{{membership.activation_date|date:'dd/MM/y'}}
              </p>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media align-items-center">
            <label class="mb-0 wid-100">Vigencia</label>
            <div class="media-body">
              <p class="mb-0"><i class="feather icon-calendar mr-1"></i>{{membership.expiration_date|date:'dd/MM/y'}}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="card hdd-right-inner">
      <div class="card-header">
        <h5>Seguimiento de Membresía</h5>
      </div>
      <div class="card-body">
        <div class="col-sm-12">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <mat-label>Agregar comentario</mat-label>
              <textarea matInput [formControl]="commentControl" rows="5" style="resize: none"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-12">
          <button type="button" (click)="submitComment()" class="btn btn-block btn-primary mt-3">AGREGAR COMENTARIO
          </button>
        </div>
      </div>
    </div>

    <div class="card task-card">
      <div class="card-header">
        <h5>Comentarios de Membresía</h5>
      </div>
      <div class="card-body">
        <div *ngIf="membership.comments?.length === 0" class="alert alert-secondary">
          No hay comentarios registrados
        </div>
        <ul *ngIf="membership.comments?.length > 0" class="list-unstyled task-list">
          <li *ngFor="let c of membership.comments">
            <i class="feather icon-check f-w-600 task-icon bg-c-green"></i>
            <p class="m-b-5"><span class="text-c-blue">{{c.created_at|date:'dd/MM/y hh:mm:ss a'}}</span></p>
            <h6 class="text-muted">{{c.comment}}</h6>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
