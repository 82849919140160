import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NoLimitsMembership} from '../../models/no-limits-membership';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MembershipStatus} from '../../models/membership';
import {MembershipStatusService} from '../../services/membership-status.service';
import {NoLimitsMembershipsService} from '../../services/no-limits-memberships.service';
import {PaymentType, Source} from '../../models/reservation';
import {PaymentTypesService} from '../../services/payment-types.service';
import {ReservationSourcesService} from '../../services/reservation-sources.service';

@Component({
  selector: 'app-no-limits-membership-detail',
  templateUrl: './no-limits-membership-detail.component.html',
  styles: [
  ]
})
export class NoLimitsMembershipDetailComponent implements OnInit {


  membershipId: string;
  membership = new NoLimitsMembership();

  membershipForm: UntypedFormGroup;
  paymentForm: UntypedFormGroup;
  sourceForm: UntypedFormGroup;
  commentControl = new UntypedFormControl(null);

  paymentTypes: PaymentType[] = [];
  sources: Source[] = [];
  status: MembershipStatus[] = [];

  canEdit: boolean;

  constructor(
    private nlMembershipsService: NoLimitsMembershipsService,
    private sourcesService: ReservationSourcesService,
    private statusService: MembershipStatusService,
    private paymentTypesService: PaymentTypesService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
  ) {
    this.route.data.subscribe(data => {
      this.canEdit = data.role === 1;
    }).unsubscribe();
    this.buildForms();
  }

  ngOnInit(): void {
    this.membershipId = this.route.snapshot.paramMap.get('id');
    const promises = [
      this.nlMembershipsService.getMembershipById(this.membershipId),
      this.statusService.getAllMembreshipStatus(),
      this.paymentTypesService.getAllPaymentTypes(),
      this.sourcesService.getAllSources(),
    ];

    Promise.all(promises).then(response => {
      this.membership = response[0]['data'];
      this.status = response[1]['data'];
      this.paymentTypes = response[2]['data'];
      this.sources = response[3]['data'];

      this.setFormsData();

    });
  }

  submitComment() {
    if (this.commentControl.valid) {
      const comment = this.commentControl.value;
      this.nlMembershipsService.createComment(this.membershipId, {comment}).then((response) => {
        this.commentControl.reset();
        this.membership.comments = response['data'];
      });
    }
  }

  submitMembershipForm() {
    if (this.membershipForm.dirty && this.membershipForm.valid) {
      this.nlMembershipsService.updateMembershipById(this.membershipId, this.membershipForm.value).then((response) => {
        this.membership = response['data'];
      });
    }
  }

  submitPaymentForm() {
    if (this.paymentForm.valid) {

      const body = {
        amount: this.paymentForm.value.amount,
        type: this.paymentForm.value.type,
        date: this.paymentForm.value.date.toISOString().split('T')[0],
      };
      this.nlMembershipsService.createPayment(this.membershipId, body).then((response) => {
        this.membership.payments = response['data'];
        this.paymentForm.reset();
      });
    }
  }

  submitSourceForm() {
    if (this.sourceForm.valid) {
      this.nlMembershipsService.updateSourceById(this.membershipId, this.sourceForm.value).then(() => {
      });
    }
  }

  private buildForms() {
    this.membershipForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, Validators.required],
      status: [null, Validators.required],
      activationDate: [null],
      expirationDate: [null],
    });

    this.paymentForm = this.formBuilder.group({
      amount: [null, Validators.required],
      type: [null, Validators.required],
      date: [null, Validators.required],
    });

    this.sourceForm = this.formBuilder.group({
      source: [null, Validators.required],
      reference: [null, Validators.required],
    });

    if (!this.canEdit) {
      this.membershipForm.disable();
      this.paymentForm.disable();
      this.sourceForm.disable();
      this.commentControl.disable();
    }
  }

  private setFormsData() {
    this.membershipForm.setValue({
      firstName: this.membership.first_name,
      lastName: this.membership.last_name,
      phone: this.membership.phone,
      email: this.membership.email,
      activationDate: this.membership.activation_date,
      expirationDate: this.membership.expiration_date,
      status: this.membership.status.id,
    });

    this.sourceForm.setValue({
      source: this.membership.source_id,
      reference: this.membership.reference
    });
  }

}
