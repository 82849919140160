import { Component, OnInit } from '@angular/core';
import {MembershipsService} from '../../services/memberships.service';
import {Router} from '@angular/router';
import Membership from '../../models/membership';

@Component({
  selector: 'app-annual-memberships',
  templateUrl: './annual-memberships.component.html',
  styles: [
  ]
})
export class AnnualMembershipsComponent implements OnInit {

  memberships: Membership[] = [];

  constructor(
    private membershipsService: MembershipsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.membershipsService.getAllMembreships().then(response => {
      this.memberships = response['data'];
    });
  }

}
