import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../../services/users.service';

@Component({
  selector: 'app-edit-user-roles',
  templateUrl: './edit-user-roles.component.html',
  styles: [
  ]
})
export class EditUserRolesComponent implements OnInit {
  user: any;
  form: UntypedFormGroup;
  canEdit: boolean;
  modules = new UntypedFormArray([]);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditUserRolesComponent>,
    public usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.user = this.data.user;
    this.canEdit = this.data.canEdit;
    this._buildForm();
    this.usersService.getUserRoles(this.user.id).then(response => {
      for (const module of response['data']) {
        this.modules.push(this.formBuilder.group({
          moduleId: module.moduleId,
          module: module.module,
          role: {value: module.role, disabled: !this.canEdit}
        }));
      }
    });
  }

  ngOnInit(): void {
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
      modules: this.formBuilder.array([])
    });

    this.modules = this.form.get('modules') as UntypedFormArray;

    if (!this.canEdit) {
      this.form.disable();
    }
  }

  onSubmit() {
    if (this.canEdit) {
      const modules = this.form.value.modules.map(m => {
        return {
          moduleId: m.moduleId,
          roleId: m.role
        };
      });
      const body = {modules};
      this.usersService.updateUserRoles(this.user.id, body).then(response => {
        this.dialogRef.close();
      }).catch(err => {
        this.dialogRef.close();
      });
    }
  }

}
