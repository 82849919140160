export const colors = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#058a00',
    secondary: '#d3ffc4',
  },
  purple: {
    primary: '#9933ff',
    secondary: '#debafd',
  },
};
