import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private url = environment.apiUrl + 'payments';
  constructor(
    private httpClient: HttpClient
  ) { }

  getPaymentsKpis() {
    return this.httpClient.get(this.url)
      .toPromise();
  }

  getPaymentsByReservationId(reservationId: string) {
    return this.httpClient.get(this.url + `/${reservationId}`)
      .toPromise();
  }

  registerPayment(reservationId: string, body: any) {
    return this.httpClient.post(this.url + `/${reservationId}`, body)
      .toPromise();
  }

  updatePayment(paymentId: any, body: any) {
    return this.httpClient.put(this.url + `/${paymentId}`, body)
      .toPromise();
  }

  deletePayment(paymentId: any) {
    return this.httpClient.delete(this.url + `/${paymentId}`)
      .toPromise();
  }
}
