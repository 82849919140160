import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FuelLoadsService {
  private url = environment.apiUrl + 'fuel-loads';
  constructor(
    private httpClient: HttpClient
  ) { }

  createLoad(body: any) {
    return this.httpClient.post(this.url, body)
      .toPromise();
  }

  updateLoad(loadId, body: any) {
    return this.httpClient.post(this.url + `/${loadId}`, body)
      .toPromise();
  }

  deleteLoad(loadId: any) {
    return this.httpClient.delete(this.url + `/${loadId}`)
      .toPromise();
  }

  getLoads(params) {
    return this.httpClient.get(this.url, {params}).toPromise();
  }

  downloadLoads(params) {
    return this.httpClient.get(this.url + `/download`,
      { observe: 'response', responseType: 'arraybuffer', params})
      .toPromise()
      .then(response => {
        const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
        const blob = new Blob([response.body], {type: response.type.toString()});
        saveAs(blob, filename);
      });
  }

  downloadLoadFile(loadId) {
    return this.httpClient.get(this.url + `/${loadId}/file`,
      { observe: 'response', responseType: 'arraybuffer'})
      .toPromise()
      .then(response => {
        const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].split('"')[1].trim();
        const blob = new Blob([response.body], {type: response.type.toString()});
        saveAs(blob, filename);
      });
  }
}
